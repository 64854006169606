// src/pages/VeterinarianVisitPage.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    LinearProgress,
    Switch,
    FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VeterinarianVisitModal from '../components/VeterinarianVisitModal'; // Make sure this path is correct
import { useAuth } from '../context/AuthContext';
import { fetchAllVetVisits } from '../services/VetVisitService';  // Import the service function
import { getAllHorses, getHorsesByUserId } from '../services/horseService';  // Imported the service function
import { fetchAllVeterinarians } from '../services/VeterinarianService';  // Imported the service function
import { useTranslation } from 'react-i18next';
import { formatRelative, format } from 'date-fns';
import GenericTableView from '../components/GenericTableView';
import GenericCardView from '../components/GenericCardView';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from '@mui/material/IconButton';
import { useTheme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { usePreferences } from '../context/UserPreferencesContext';
import { useDateLocale } from '../hooks/useDateLocale';

const VeterinarianVisitPage = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [vetVisits, setVetVisits] = useState([]);  // To store vet visits from the backend
    const { apiKey, organizationId, token, userType, userId, ownerId } = useAuth();
    const [vets, setVets] = useState([]);
    const [horses, setHorses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const [viewOwnVisits, setViewOwnVisits] = useState(true);
    const [ownerHorses, setOwnerHorses] = useState([]);
    const { devicePreferences, updateDevicePreferences } = usePreferences();




    // Function to toggle view mode
    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);  // Set loading to true
                const vetVisitData = await fetchAllVetVisits(apiKey, organizationId, token);
                setVetVisits(vetVisitData);

                let horseData;
                if (userType === 'admin') {
                    horseData = await getAllHorses(apiKey, organizationId, token);
                } else {
                    horseData = await getHorsesByUserId(userId, apiKey, organizationId, token);
                }
                setHorses(horseData);
                setOwnerHorses(horseData.filter(horse => horse.ownerId === ownerId));
                const vetData = await fetchAllVeterinarians(apiKey, organizationId, token);

                setVets(vetData);

            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setIsLoading(false);  // Set loading to false
            }
        };
        fetchData();
    }, [apiKey, organizationId, token, ownerId, isModalOpen]);


    // Update local state when context updates
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);


    const getVetNameById = (vetId, viit) => {
        const vet = vets.find(v => v.id === vetId);
        return vet ? vet.firstname + ' ' + vet.surname : viit.vetName || 'Unknown Vet';
    };

    const getHorseNameById = (horseId) => {
        const horse = horses.find(h => h.id === horseId);
        return horse ? horse.name : 'Unknown Horse';
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentRecord(null); // reset the current record when the modal is closed
    };

    const handleAddVisit = () => {
        setCurrentRecord(null); // no current record for adding a new visit
        setModalOpen(true);
    };

    const handleEditVisit = (id) => {
        const record = vetVisits.find(v => v.id === id);
        setCurrentRecord(record);
        setModalOpen(true);
    };

    const transformData = (visitsData) => {
        return {
            headers: [{
                key: 'horseName', label: t('veterinarianVisits.Page.tableHeaders.horseName'), display: { value: true, chipColor: false }
            }, {
                key: 'dateOfVisit', label: t('veterinarianVisits.Page.tableHeaders.dateOfVisit'), display: { value: true, chipColor: false }
            }, {
                key: 'vetName', label: t('veterinarianVisits.Page.tableHeaders.vetName'), display: { value: true, chipColor: false }
            },
            ],
            rows: visitsData.map(visit => ({
                id: visit.id,
                horseName: {
                    value: getHorseNameById(visit.horseId),
                    icon: null
                },
                dateOfVisit: {
                    value: format(new Date(visit.visitDate), 'P', { locale: dateLocale }),
                    icon: null
                },
                vetName: {
                    value: getVetNameById(visit.vetId, visit),
                    icon: null
                }
            }))
        };
    };



    const filteredVisits = useMemo(() => {
        if (!viewOwnVisits) {
            return vetVisits;
        }
        // Filter to return only the user's visits if the toggle is off
        return vetVisits.filter(v => ownerHorses.some(h => h.id === v.horseId));
    }, [viewOwnVisits, vetVisits, ownerHorses]);

    return (

        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <div>

                <Typography variant="h4" gutterBottom>
                    {t('veterinarianVisits.Page.title')}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddVisit}
                    >
                        {t('veterinarianVisits.Page.addNewVisit')}
                    </Button>
                    <FormControlLabel
                        control={<
                            Switch
                            checked={viewOwnVisits}
                            onChange={() => setViewOwnVisits(!viewOwnVisits)}
                            name="viewMode"
                            color="primary"
                        />
                        }
                        label={t('veterinarianVisits.Page.viewOwnVisits')}
                    />
                    <IconButton onClick={toggleViewMode} color="primary">
                        {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                    </IconButton>
                </Box>
                {isLoading ? (
                    <LinearProgress />
                ) : viewMode === 'table' ? (
                    <GenericTableView structure={transformData(filteredVisits)} onClick={handleEditVisit} />
                ) : (
                    <GenericCardView structure={transformData(filteredVisits)} onClick={handleEditVisit} />
                )}
                <VeterinarianVisitModal isOpen={isModalOpen} onClose={handleModalClose} record={currentRecord} horses={horses} vets={vets} />
            </div>
        </Paper>

    );
};

export default VeterinarianVisitPage;
