import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/horses';

export async function getAllHorses(apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(API_BASE_URL);
  return response.data;
}

export async function getHorsesByUserId(userId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?userId=${userId}`);
  return response.data;
}

export async function getHorsesByOwnerID(ownerId, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?ownerId=${ownerId}`);
  return response.data;
}

export async function getHorsesByOwnerEmail(ownerEmail, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?ownerEmail=${ownerEmail}`);
  return response.data;
}

export async function getHorseById(id, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/${id}`);
  return response.data;
}

export async function createHorse(horse, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.post(API_BASE_URL, horse);
  return response.data;
}

export async function updateHorse(id, horse, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.put(`${API_BASE_URL}/${id}`, horse);
  return response.data;
}

export async function deleteHorse(id, apiKey, organizationId, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.delete(`${API_BASE_URL}/${id}`);
  return response.data;
}

export async function getHorsePermissions(horseId, organizationId, apiKey, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/permissions?horseId=${horseId}`);
  return response.data;
}

export async function upsertHorsePermissions(horseId, permissions, organizationId, apiKey, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.put(`${API_BASE_URL}/permissions?horseId=${horseId}`, permissions);
  return response.data;
}

export async function printHorseProfile(horseId, organizationId, apiKey, jwtToken, language) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  // IMPORTANT: set responseType: 'blob' so we get the PDF as binary
  const response = await axiosInstance.get(`${API_BASE_URL}/printBoxLabel?horseId=${horseId}&lang=${language}`, {
    responseType: 'blob',
    headers: {
      'Accept': 'application/pdf' // helps the server know we expect a PDF
    }
  });

  // Here, response.data is a Blob containing the PDF
  return response.data;
}

export default {
  getAllHorses,
  getHorsesByOwnerID,
  getHorsesByOwnerEmail,
  getHorseById,
  createHorse,
  updateHorse,
  deleteHorse,
  getHorsePermissions,
  upsertHorsePermissions,
  printHorseProfile,
};
