import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

import { useAuth } from '../../context/AuthContext';
import { fetchAllBoxTypes } from '../../services/BoxService';
import { fetchPricingSettings } from '../../services/PricingService';

const DetailsTab = ({ horse, handleInputChange, setHorse, owners }) => {
  const { t } = useTranslation();
  const { userType, apiKey, token, organizationId } = useAuth();
  const isAdmin = userType === "admin";
  const [boxTypes, setBoxTypes] = useState([]);
  const [pricingSettings, setPricingSettings] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState('panel1');

  useEffect(() => {
    fetchAllBoxTypes(apiKey, organizationId, token).then(setBoxTypes);
    if (isAdmin) {
      fetchPricingSettings(apiKey, organizationId, token).then(setPricingSettings);
    }
  }, [isAdmin, apiKey, organizationId, token]);

  const handleBoxTypeChange = (event) => {
    handleInputChange({
      target: {
        name: 'boxTypeId',
        value: event.target.value,
      },
    });
  };

  const handlePriceChange = (event) => {
    handleInputChange({
      target: {
        name: 'pricingId',
        value: event.target.value,
      },
    });
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  const clearSelection = (field) => {
    setHorse((prevHorse) => ({ ...prevHorse, [field]: '' }));
  };

  useEffect(() => {
    handleInputChange({
      target: {
        name: 'pricingId',
        value: '',
      },
    });
  }, [horse.boxTypeId]);

  const changeBoxType = (event) => {
    setHorse((prevHorse) => ({
      ...prevHorse,
      boxTypeId: event.target.value,
    }));
  };

  const filteredPricingSettings = pricingSettings.filter(
    (pricing) => pricing.referenceId === horse.boxTypeId
  );

  return (
    <Box sx={{ mt: 4 }}>
      <>
        {/* Identification and Microchip */}
        <Accordion expanded={expandedAccordion === 'panel1'} onChange={handleChangeAccordion('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('HorseInfoPage.basicInfo')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mt: 4 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.name')}
                name="name"
                value={horse.name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <DatePicker
                fullWidth
                label={t('HorseInfoPage.birthday')}
                value={horse.birthday || null}
                onChange={(newValue) => {
                  setHorse({ ...horse, birthday: newValue });
                }}
                renderInput={(params) => <TextField {...params} fullWidth required />}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.breed')}
                name="breed"
                value={horse.breed}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>

            <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
              <InputLabel>{t('HorseInfoPage.owner')}</InputLabel>
              <Select
                value={horse.ownerId}
                onChange={(e) =>
                  setHorse((prevHorse) => ({ ...prevHorse, ownerId: e.target.value }))
                }
                label={t('HorseInfoPage.owner')}
                disabled={!isAdmin}
                endAdornment={
                  horse.ownerId && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => clearSelection('ownerId')}
                        size="small"
                        style={{ marginRight: 12 }}
                        disabled={!isAdmin}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {owners.map((owner) => (
                  <MenuItem key={owner.id} value={owner.id}>
                    {owner.firstname} {owner.surname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
              <InputLabel>{t('HorseInfoPage.boxType')}</InputLabel>
              <Select
                value={horse.boxTypeId || ''}
                onChange={changeBoxType}
                label={t('HorseInfoPage.boxType')}
                endAdornment={
                  horse.boxTypeId && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => clearSelection('boxTypeId')} size="small" style={{ marginRight: 12 }}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {boxTypes.map((boxType) => (
                  <MenuItem key={boxType.id} value={boxType.id}>
                    {boxType.name} ({boxType.location})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isAdmin && (
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <InputLabel>{t('HorseInfoPage.pricing')}</InputLabel>
                <Select
                  value={horse.pricingId || ''}
                  onChange={handlePriceChange}
                  label={t('HorseInfoPage.pricing')}
                  disabled={!horse.boxTypeId}
                  endAdornment={
                    horse.pricingId && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => clearSelection('pricingId')} size="small" style={{ marginRight: 12 }}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                >
                  {filteredPricingSettings.map((pricing) => (
                    <MenuItem key={pricing.pricingId} value={pricing.pricingId}>
                      {boxTypes.find((boxType) => boxType.id === pricing.referenceId)?.name} - {pricing.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.identificationNumber')}
                name="identificationNumber"
                value={horse.identificationNumber}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.color')}
                name="color"
                value={horse.color}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.sex')}
                name="sex"
                value={horse.sex}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Identification and Microchip */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('HorseInfoPage.identificationMicrochip.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mt: 2 }}>
              <DatePicker
                fullWidth
                label={t('HorseInfoPage.identificationMicrochip.dateOfInsertion')}
                value={horse?.microchipIdentification?.dateOfInsertion || null}
                onChange={(newValue) => {
                  setHorse({
                    ...horse,
                    microchipIdentification: {
                      ...horse.microchipIdentification,
                      dateOfInsertion: newValue,
                    },
                  });
                }}
                renderInput={(params) => <TextField {...params} fullWidth required />}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.identificationMicrochip.locationOfInsertion')}
                name="microchipIdentification.locationOfInsertion"
                value={horse.microchipIdentification.locationOfInsertion}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.identificationMicrochip.number')}
                name="microchipIdentification.number"
                value={horse.microchipIdentification.number}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.identificationMicrochip.issuedBy')}
                name="microchipIdentification.issuedBy"
                value={horse.microchipIdentification.issuedBy}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Sire and Dam */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('HorseInfoPage.sireAndDam.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.sireAndDam.sire.name')}
                name="sire.name"
                value={horse.sire.name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.sireAndDam.sire.identificationNumber')}
                name="sire.identificationNumber"
                value={horse.sire.identificationNumber}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.sireAndDam.dam.name')}
                name="dam.name"
                value={horse.dam.name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.sireAndDam.dam.identificationNumber')}
                name="dam.identificationNumber"
                value={horse.dam.identificationNumber}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Breeder */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('HorseInfoPage.breeder.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.breeder.name')}
                name="breeder.name"
                value={horse.breeder.name}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.breeder.address')}
                name="breeder.address"
                value={horse.breeder.address}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Markings */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('HorseInfoPage.markings.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.markings.head')}
                name="markings.head"
                value={horse.markings?.head}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.markings.legs.frontLeft')}
                name="markings.legs.frontLeft"
                value={horse.markings?.legs?.frontLeft || ''}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.markings.legs.frontRight')}
                name="markings.legs.frontRight"
                value={horse.markings?.legs?.frontRight || ''}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.markings.legs.backLeft')}
                name="markings.legs.backLeft"
                value={horse.markings?.legs?.backLeft || ''}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.markings.legs.backRight')}
                name="markings.legs.backRight"
                value={horse.markings?.legs?.backRight || ''}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label={t('HorseInfoPage.markings.body')}
                name="markings.body"
                value={horse.markings?.body}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </>
    </Box>
  );
};

export default DetailsTab;
