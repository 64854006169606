// src/components/SelfSignup/HorseForm.js

import { useState, useEffect } from 'react';
import { TextField, Grid, Button, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
// Your custom hook
import AddPersonModal from './AddPersonModal';

const HorseForm = ({ onRemove, initialData = {}, onUpdate, veterinarians = [], ferries = [], addNewPerson }) => {
    const { t } = useTranslation();
    const [modalInfo, setModalInfo] = useState({
        open: false,
        type: null, // 'veterinarian' or 'farrier'
    });

    const [isAddingVet, setIsAddingVet] = useState(false);
    const defaultHorseData = {
        name: '',
        birthday: '',
        breed: '',
        emergencyContact: {
            name: '',
            number: '',
        },
        veterinarianId: '',
        farrierId: '',
    };

    const [horse, setHorse] = useState({
        ...defaultHorseData,
        ...initialData,
        emergencyContact: {
            ...defaultHorseData.emergencyContact,
            ...(initialData && initialData.emergencyContact)
        },
        veterinarianId: initialData ? initialData.veterinarianId : '',
        farrierId: initialData ? initialData.farrierId : '',

    });

    const handleAddNewPerson = (person) => {
        const newPerson = {
            id: Math.random().toString(36).substr(2, 9), // Generating a pseudo-random ID
            ...person,
        };

        if (modalInfo.type === 'veterinarian') {
            const person = {
                id: Math.random().toString(36).substr(2, 9), // Generating a pseudo-random ID
                firstname: newPerson.firstname,
                surname: newPerson.surname,
                phoneNumber: newPerson.phoneNumber,
            };
            addNewPerson('veterinarians', person);
        } else if (modalInfo.type === 'farrier') {
            const person = {
                id: Math.random().toString(36).substr(2, 9), // Generating a pseudo-random ID
                firstname: newPerson.firstname,
                surname: newPerson.surname,
                phoneNumber: newPerson.phoneNumber,
            };
            addNewPerson('ferries', person);
        }

        setModalInfo({ open: false, type: null });
    };


    // Combine fetched and new entities for display
    const combinedVeterinarians = [
        ...veterinarians,
        ...(initialData.newPersons?.veterinarians || [])
    ];
    const combinedFerries = [
        ...ferries,
        ...(initialData.newPersons?.ferries || [])
    ];

    useEffect(() => {
        onUpdate(horse);
    }, [horse, onUpdate]);

    const handleInputChange = (e, category) => {
        const { name, value } = e.target;

        if (category) {
            setHorse(prev => ({
                ...prev,
                [category]: {
                    ...prev[category],
                    [name]: value
                }
            }));
        } else {
            setHorse(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleOpenModal = (type) => {
        setModalInfo({ open: true, type });
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('HorseForm.labels.name')}
                        name="name"
                        value={horse.name}
                        onChange={handleInputChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <DatePicker
                        label={t('HorseForm.labels.birthday')}
                        value={horse.birthday}
                        onChange={(newValue) => setHorse(prev => ({ ...prev, birthday: newValue }))}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('HorseForm.labels.breed')}
                        name="breed"
                        value={horse.breed}
                        onChange={handleInputChange}
                    />
                </Grid>
                {/* Emergency Contact */}
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('HorseForm.labels.emergencyContactName')}
                        name="name"
                        value={horse.emergencyContact.name}
                        onChange={e => handleInputChange(e, 'emergencyContact')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('HorseForm.labels.emergencyContactNumber')}
                        name="number"
                        value={horse.emergencyContact.number}
                        onChange={e => handleInputChange(e, 'emergencyContact')}
                    />
                </Grid>
                {/* Veterinarian */}
                <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{t('HorseForm.labels.veterinarianName')}</InputLabel>
                            <Select
                                name="veterinarianId"
                                value={horse.veterinarianId || ''}
                                onChange={e => {
                                    if (e.target.value === "add_new") {
                                        handleOpenModal('veterinarian');
                                    } else {
                                        handleInputChange({ target: { name: 'veterinarianId', value: e.target.value } });
                                    }
                                }}
                                label={t('HorseForm.labels.veterinarianName')}
                            >
                                <MenuItem value="add_new">{t('HorseForm.labels.addNewVeterinarian')}</MenuItem>
                                {combinedVeterinarians.map(vet => (
                                    <MenuItem key={vet.id} value={vet.id}>
                                        {vet.firstname} {vet.surname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {/* Farrier */}
                <Grid item xs={6}>
                    <Box sx={{ mt: 2 }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{t('HorseForm.labels.farrierName')}</InputLabel>
                            <Select
                                name="farrierId"
                                value={horse.farrierId || ''}
                                onChange={e => {
                                    if (e.target.value === "add_new") {
                                        handleOpenModal('farrier');
                                    } else {
                                        handleInputChange({ target: { name: 'farrierId', value: e.target.value } });
                                    }
                                }}
                                label={t('HorseForm.labels.farrierName')}
                            >
                                <MenuItem value="add_new">{t('HorseForm.labels.addNewFarrier')}</MenuItem>
                                {combinedFerries.map(farrier => (
                                    <MenuItem key={farrier.id} value={farrier.id}>
                                        {farrier.firstname} {farrier.surname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={onRemove} variant="contained" color="error">
                        {t('HorseForm.labels.removeHorse')}
                    </Button>
                </Grid>
            </Grid>
            <AddPersonModal
                open={modalInfo.open}
                onClose={() => setModalInfo({ open: false, type: null })}
                onSave={handleAddNewPerson}
                title={modalInfo.type === 'veterinarian' ? t('HorseForm.addNewVeterinarianTitle') : t('HorseForm.addNewFarrierTitle')}
            />
        </>
    );
};

export default HorseForm;
