import React from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Button, Container, Grid, TextField, Typography, MenuItem, Select, Autocomplete } from '@mui/material';
import { usePaymentReminder } from './usePaymentReminder';


import { DatePicker } from '@mui/x-date-pickers';

import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import SnackbarAlert from '../../components/SnackbarAlert';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

const PaymentReminder = () => {
    const { paymentReminderId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        paymentReminder: paymentReminderData,
        paymentReminderSettings,
        handleInputChange,
        handleDateChange,
        unpaidInvoices,
        handleInvoicesChange,
        previousReminders,
        handlePreviousReminderChange,
        handleEscalateReminder,
        handleAutocompleteChange,
        handleSubmit,
        handleDelete,
        owners,
        loading,
        isEditMode,
        error,
        snackbar,
        handleSnackbarClose,
        confirmDialogOpen,
        handleConfirmDialogClose,
        handlePaymentConfirmation,
        handleMarkAsPaidClick,
        handleSendReminderEmail,
        handleDownloadReminderPDF,
    } = usePaymentReminder(paymentReminderId);
    
    const isPaid = paymentReminderData.status === 'paid';

    return (
        
            <Container>
                <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h4">
                        <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
                        {paymentReminderId === 'new' ? t('paymentReminder.newPaymentReminder') : t('paymentReminder.editPaymentReminder')}
                    </Typography>
                    {loading ? (
                        <div style={{ width: '100%', marginTop: 20 }}>
                            <LinearProgress />
                        </div>
                    ) : null}
                    <Grid container spacing={3} style={{ marginTop: '10px' }}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={owners}
                                getOptionLabel={(owner) => `${owner.firstname} ${owner.surname}`}
                                freeSolo
                                disabled={isEditMode}
                                value={owners.find(owner => owner.id === paymentReminderData.customerId) || null}
                                onChange={handleAutocompleteChange}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('paymentReminder.payerName')} fullWidth disabled={isEditMode} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Select
                                label={t('paymentReminder.level')}
                                value={paymentReminderData.level || ''}
                                name="level"
                                onChange={handleInputChange}
                                fullWidth
                                disabled={isEditMode}
                            >
                                {paymentReminderSettings.map(level => (
                                    <MenuItem key={level.reminderLevel} value={level.reminderLevel}>
                                        {t(`paymentReminder.level${level.reminderLevel}`)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {/* Reminder Type Selection */}
                        <Grid item xs={12}>
                            <Select
                                label={t('paymentReminder.reminderType')}
                                value={paymentReminderData.reminderType || 'paymentReminder'}
                                name="reminderType"
                                onChange={handleInputChange}
                                fullWidth
                            >
                                <MenuItem value="paymentReminder">{t('paymentReminder.paymentReminder')}</MenuItem>
                                <MenuItem value="dunningLetter">{t('paymentReminder.dunningLetter')}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                options={unpaidInvoices.filter(invoice => invoice.ownerId === paymentReminderData.customerId)}
                                getOptionLabel={(invoice) => `${invoice.customerName} - ${invoice.invoiceNumber} (€${invoice.totalAmount})`}
                                value={paymentReminderData.invoices || []}
                                onChange={handleInvoicesChange}
                                renderInput={(params) => <TextField {...params} label="Select Unpaid Invoices" />}
                                disabled={isPaid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('paymentReminder.openInvoicesSumGross')}
                                type="number"
                                name="openInvoicesSumGross"
                                fullWidth
                                value={paymentReminderData.openInvoicesSumGross || ''}
                                onChange={handleInputChange}
                                disabled
                            />
                        </Grid>
                        {/* Fee Fields */}
                        <Grid item xs={12}>
                            <TextField
                                label={t('paymentReminder.noticeFeeInPercentage')}
                                type="number"
                                name="noticeFeeInPercentage"
                                fullWidth
                                value={paymentReminderData.noticeFeeInPercentage || ''}
                                onChange={handleInputChange}
                                disabled={paymentReminderData.reminderType === 'paymentReminder' || isEditMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('paymentReminder.totalGrossAmount')}
                                type="number"
                                name="totalGrossAmount"
                                fullWidth
                                value={paymentReminderData.totalGrossAmount || ''}
                                onChange={handleInputChange}
                                disabled={paymentReminderData.reminderType === 'paymentReminder' || isEditMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label={t('paymentReminder.creationDate')}
                                name="creationDate"
                                value={paymentReminderData.creationDate || new Date()}
                                onChange={(date) => handleDateChange(date, 'creationDate')}
                                renderInput={(params) => <TextField {...params} />}
                                fullWidth
                                disabled={isEditMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label={t('paymentReminder.dueDate')}
                                name="dueDate"
                                value={paymentReminderData.dueDate || new Date()}
                                onChange={(date) => handleDateChange(date, 'dueDate')}
                                renderInput={(params) => <TextField {...params} />}
                                fullWidth
                                disabled={isEditMode}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('paymentReminder.printAdditionalText')}
                                name="printAdditionalText"
                                fullWidth
                                value={paymentReminderData.printAdditionalText || ''}
                                onChange={handleInputChange}
                                disabled={isPaid}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    options={previousReminders?.filter(
                                        (reminder) =>
                                            reminder.level < paymentReminderData.level &&
                                            reminder.customerId === paymentReminderData.customerId &&
                                            reminder.status === 'unpaid'
                                    )}
                                    getOptionLabel={(reminder) => `Level ${reminder.level} - Sent on ${new Date(reminder.creationDate).toLocaleDateString()}`}
                                    value={previousReminders.filter(reminder => paymentReminderData.previousReminderIds.includes(reminder.id)) || []}
                                    onChange={handlePreviousReminderChange}
                                    renderInput={(params) => <TextField {...params} label="Select Previous Reminders (if applicable)" />}
                                    disabled={isEditMode}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '20px' }}>
                        {/* Primary Save Action */}
                        <Grid item xs={12} md={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={paymentReminderData.invoices.length === 0 || loading}
                                fullWidth
                            >
                                {loading ? <CircularProgress size={24} /> : t('paymentReminder.save')}
                            </Button>
                        </Grid>

                        {/* Payment-Related Actions */}
                        <Grid item xs={6} md={3}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleMarkAsPaidClick}
                                disabled={paymentReminderData.invoices.length === 0 || loading || isPaid || !isEditMode}
                                fullWidth
                            >
                                {t('paymentReminder.markAsPaid')}
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleEscalateReminder}
                                disabled={paymentReminderData.level >= 3 || loading || isPaid || !isEditMode}
                                fullWidth
                            >
                                {t('paymentReminder.escalate')}
                            </Button>
                        </Grid>

                        {/* Communication Actions */}
                        <Grid item xs={6} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSendReminderEmail}
                                disabled={loading || isPaid || !isEditMode}
                                fullWidth
                            >
                                {t('paymentReminder.sendReminder')}
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleDownloadReminderPDF}
                                disabled={loading || isPaid || !isEditMode}
                                fullWidth
                            >
                                {t('paymentReminder.downloadPDF')}
                            </Button>
                        </Grid>

                        {/* Destructive Action */}
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleDelete}
                                disabled={loading || isPaid || !isEditMode}
                                fullWidth
                            >
                                {t('paymentReminder.delete')}
                            </Button>
                        </Grid>
                    </Grid>

                </Paper>
                <SnackbarAlert
                    open={snackbar.open}
                    onClose={handleSnackbarClose}
                    message={snackbar.message}
                    severity={snackbar.severity}
                />
                <ConfirmationDialog
                    isOpen={confirmDialogOpen}
                    onClose={handleConfirmDialogClose}
                    onConfirm={handlePaymentConfirmation}
                    text={t("invoicesListPage.confirmPayment")}
                />
            </Container>
        
    );
};

export default PaymentReminder;
