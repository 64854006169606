// src/pages/inventoryManagement/inventoryManagement/TransactionInfoPage.js
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Paper, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { useTransactionManagement } from './useTransactionManagement'; // Custom hook for transaction management
import { useTranslation } from 'react-i18next';


import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../../hooks/useDateLocale';
import { format } from 'date-fns';
import LinearProgress from '@mui/material/LinearProgress';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const InventoryTransactionInfoPage = ({ onClose }) => {
  const { transactionId } = useParams();
  const navigate = useNavigate();
  
  const { t } = useTranslation();
  const isEditMode = transactionId && transactionId !== 'new';
  const {
    transactionData,
    handleInputChange,
    filteredReasons,
    transactionTypes,
    handleSubmit,
    handleItemSelection,
    handleDateChange,
    getLocalizedMeasureUnit,
    selectedItem,
    loading,
    items,
    error
  } = useTransactionManagement(transactionId, isEditMode, onClose); // Your custom hook to manage transaction data


  if (loading) return <LinearProgress />;
  if (error) return <div>Error: {error}</div>;

  return (

    
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4">
          <IconButton onClick={() => navigate(-1)} aria-label="back"><ArrowBackIcon /></IconButton>
          {t('TransactionInfoPage.title')}
        </Typography>
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('TransactionInfoPage.item')}</InputLabel>
            <Select
              name="itemId"
              value={transactionData.itemId}
              onChange={handleItemSelection}
            >
              {items.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name} {/* Assuming each item has an 'id' and 'name' */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DatePicker
            label={t('TransactionInfoPage.date')}
            value={transactionData.date}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('TransactionInfoPage.type')}</InputLabel>
            <Select
              name="type"
              value={transactionData.type}
              onChange={handleInputChange}
            >
              {transactionTypes.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('TransactionInfoPage.reason')}</InputLabel>
            <Select
              name="reason"
              value={transactionData.reason}
              onChange={handleInputChange}
            >
              {filteredReasons.map((reason, index) => (
                <MenuItem key={index} value={reason.value}>
                  {reason.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label={t('TransactionInfoPage.comment')}
              name="comment"
              value={transactionData.comment}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </FormControl>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginY: 2 }}>
            <TextField
              label={t('TransactionInfoPage.quantity')}
              name="quantity"
              type="number"
              value={transactionData.quantity}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
              {selectedItem ? getLocalizedMeasureUnit(selectedItem.measureUnit) : t('TransactionInfoPage.defaultUnit')}
            </Typography>
          </Box>
          {isEditMode && (
            <>
              <TextField
                label={t('TransactionInfoPage.oldQuantity')}
                name="oldQuantity"
                value={transactionData.oldquantity}
                fullWidth
                disabled
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label={t('TransactionInfoPage.newQuantity')}
                name="newQuantity"
                value={transactionData.newquantity}
                fullWidth
                disabled
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            </>
          )}
          <Button type="submit" variant="contained" color="primary">
            {!isEditMode ? t('TransactionInfoPage.add') : t('TransactionInfoPage.edit')}
          </Button>
        </form>
      </Paper>
    
  );
};

export default InventoryTransactionInfoPage;
