// src/services/calendarEventsService.js
import axiosInstance, { 
    setApiKeyHeader, 
    setOrganizationIdHeader, 
    setAuthHeader 
  } from './apiConfig/axiosConfig.js';
  
  // The endpoint that serves the combined data of riding lessons + activity votes
  // Adjust if you used a different route name (e.g., '/api/CalendarEvents').
  const API_BASE_URL = '/api/building/calenderView';
  
  /**
   * Fetch combined calendar events (riding lessons + horse activities / votes).
   * 
   * @param {string} apiKey           - Your API key (if required by headers).
   * @param {string} organizationId   - The organization ID header.
   * @param {string} jwtToken         - The user’s JWT token for authorization.
   * @param {Object} [filters={}]     - Optional filters (e.g., { startDate, endDate, horseId, userId }).
   * @returns {Promise<Array>}        - Resolves with an array of combined events.
   */
  export async function getCalendarEvents(apiKey, organizationId, jwtToken, filters = {}) {
    // 1) Set the custom headers
    setApiKeyHeader(apiKey);
    setOrganizationIdHeader(organizationId);
    setAuthHeader(jwtToken);
  
    // 2) Build query string if you have optional filters
    // For example: ?startDate=2023-01-01T00:00:00.000Z&endDate=2023-01-31T23:59:59.999Z&horseId=123
    // We'll convert the filters object into a query string
    const queryParams = new URLSearchParams(filters).toString();
    const url = queryParams ? `${API_BASE_URL}?${queryParams}` : API_BASE_URL;
  
    // 3) Perform GET request
    const response = await axiosInstance.get(url);
    return response.data; // Return the array of combined events
  }
  
  // -------------------------------------------------------------------------
  // If you later implement create, update, delete for combined events, 
  // you can add them here. For example:
  //
  // export async function createCalendarEvent(eventData, apiKey, organizationId, jwtToken) { ... }
  //
  // export async function updateCalendarEvent(eventData, apiKey, organizationId, jwtToken) { ... }
  //
  // export async function deleteCalendarEvent(eventId, apiKey, organizationId, jwtToken) { ... }
  // -------------------------------------------------------------------------
  