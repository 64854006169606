// src/components/FarmDesigner/PrintPreviewModal.js
import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    InputLabel,
    FormControl,
} from '@mui/material';
import ReactGridLayout from 'react-grid-layout';
import { useAuth } from '../../context/AuthContext';
import { fetchFarmLayout } from '../../services/FarmLayoutService';
import { fetchLocationSettings } from '../../services/LocationSettingsService';
import { fetchFoodSettings } from '../../services/FoodSettingsService';
import { getHorseById } from '../../services/horseService';
import { useTranslation } from 'react-i18next';

// ---- NEW IMPORT ----
import { useReactToPrint } from 'react-to-print';

import BoxItem from './BoxItem';
import RoomItem from './RoomItem';

const PrintPreviewModal = ({
    isOpen,
    onClose,
    layout: propLayout,
    boxes: propBoxes,
    rooms: propRooms,
    isInitializing,
    filteredItems: propFilteredItems,
}) => {
    const { t } = useTranslation();
    const { apiKey, organizationId, token: jwtToken } = useAuth();

    const [internalLayout, setInternalLayout] = useState(propLayout || []);
    const [boxes, setBoxes] = useState(propBoxes || []);
    const [rooms, setRooms] = useState(propRooms || []);
    const [filteredItems, setFilteredItems] = useState(propFilteredItems || []);

    const [locations, setLocations] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedBuilding, setSelectedBuilding] = useState('');

    // For toggling display of food portions
    const [showFoodPortions, setShowFoodPortions] = useState(false);
    const [foodSettings, setFoodSettings] = useState([]);

    // Paper size & orientation
    const [paperSize, setPaperSize] = useState('A4');
    const [orientation, setOrientation] = useState('landscape');

    // We’ll store the printing content ref
    const printAreaRef = useRef(null);

    // Ensure ref is assigned when modal opens or layout updates
    useEffect(() => {
        if (isOpen && internalLayout.length > 0) {
            setTimeout(() => {
                if (!printAreaRef.current) {
                    console.warn("Warning: printAreaRef is still null after modal opened.");
                }
            }, 100); // Small delay to let DOM update
        }
    }, [isOpen, internalLayout.length]);


    // This sets up the actual print function
    const handlePrint = useReactToPrint({
        content: () => {
            if (!printAreaRef.current) {
                console.warn("Nothing to print: printAreaRef is null.");
                return null;
            }
            return printAreaRef.current;
        },
        documentTitle: 'FarmLayout',
        removeAfterPrint: true,
        copyStyles: true,
    });

    // Load location if user tries to select location/building from scratch
    useEffect(() => {
        if (!propLayout || propLayout.length === 0) {
            const fetchData = async () => {
                try {
                    const locationsData = await fetchLocationSettings(apiKey, organizationId, jwtToken);
                    setLocations(locationsData);
                } catch (error) {
                    console.error('Error fetching locations', error);
                }
            };
            fetchData();
        }
    }, [isOpen, apiKey, organizationId, jwtToken, propLayout]);

    // Fetch food settings
    useEffect(() => {
        const fetchFoodData = async () => {
            try {
                const foodData = await fetchFoodSettings(apiKey, organizationId, jwtToken);
                setFoodSettings(foodData);
            } catch (error) {
                console.error('Error fetching food settings', error);
            }
        };
        fetchFoodData();
    }, [apiKey, organizationId, jwtToken]);

    // If user selects a location from scratch
    const handleLocationChange = useCallback(
        (event) => {
            const locationId = event.target.value;
            setSelectedLocation(locationId);

            const selectedLocationData = locations.find(
                (location) => location.id === locationId
            );
            setBuildings(selectedLocationData?.buildings || []);
            setSelectedBuilding('');
        },
        [locations]
    );

    // If user selects a building from scratch
    const handleBuildingChange = useCallback(
        async (event) => {
            const buildingId = event.target.value;
            setSelectedBuilding(buildingId);

            try {
                const data = await fetchFarmLayout(
                    apiKey,
                    organizationId,
                    jwtToken,
                    selectedLocation,
                    buildingId
                );
                if (data && data.layoutData.layout.length > 0) {
                    const { layout, boxes: fetchedBoxes, rooms: fetchedRooms } = data.layoutData;
                    setInternalLayout(layout);
                    setBoxes(fetchedBoxes || []);
                    setRooms(fetchedRooms || []);
                    setFilteredItems([...(fetchedBoxes || []), ...(fetchedRooms || [])]);

                    // fetch horse data so we have full horse details
                    const horseIds = (fetchedBoxes || [])
                        .filter((box) => box.horse && box.horse.id)
                        .map((box) => box.horse.id);
                    const uniqueHorseIds = [...new Set(horseIds)];

                    if (uniqueHorseIds.length > 0) {
                        const horseDataArray = await Promise.all(
                            uniqueHorseIds.map((horseId) =>
                                getHorseById(horseId, apiKey, organizationId, jwtToken)
                            )
                        );
                        const horseDataMap = {};
                        horseDataArray.forEach((hd) => {
                            horseDataMap[hd.id] = hd;
                        });
                        const updatedBoxes = (fetchedBoxes || []).map((box) => {
                            if (box.horse && box.horse.id && horseDataMap[box.horse.id]) {
                                return {
                                    ...box,
                                    horse: horseDataMap[box.horse.id],
                                };
                            }
                            return box;
                        });
                        setBoxes(updatedBoxes);
                        setFilteredItems([...updatedBoxes, ...(fetchedRooms || [])]);
                    }
                } else {
                    console.warn('No layout data found for selected location and building');
                }
            } catch (error) {
                console.error('Error fetching farm layout', error);
            }
        },
        [apiKey, organizationId, jwtToken, selectedLocation]
    );

    // We’ll compute the grid size for preview
    // (A4 vs. A3, orientation, etc. are mostly handled by CSS in the print)
    // but let’s still define a "preview" dimension so it looks correct in the modal:
    const previewWidth = orientation === 'landscape' ? 1122 : 793; // approximate
    const previewHeight = orientation === 'landscape' ? 793 : 1122; // approximate

    // We can store rowHeight, # of columns, etc. as we do in FarmLayout
    // but for the preview, it can be simplified or matched to your building dimension
    // Just keep it consistent with the layout:

    // Example: If your main building is 12m wide => 12 columns
    // same rowHeight as FarmLayout (30 px per meter).
    const cols = 12;
    const rowHeight = 30;

    // Dynamically create the <style> for printing paper size & orientation
    // This is injected in the print if your browser supports it
    const printStyle = `
      @page {
        size: ${paperSize} ${orientation};
        margin: 10mm;
      }
      @media all {
        .no-print {
          display: none !important;
        }
      }
    `;

    // Render
    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {t('PrintPreviewModal.Title')}
                </Typography>

                {/* If we have an existing layout passed in */}
                {internalLayout && internalLayout.length > 0 ? (
                    <>
                        {/* Toggle for food portions */}
                        <Box sx={{ mt: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showFoodPortions}
                                        onChange={(e) => setShowFoodPortions(e.target.checked)}
                                    />
                                }
                                label={t('PrintPreviewModal.ShowFoodPortions')}
                            />
                        </Box>

                        {/* Select paper size and orientation */}
                        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                            <FormControl fullWidth>
                                <InputLabel id="paper-size-label">
                                    {t('PrintPreviewModal.PaperSize')}
                                </InputLabel>
                                <Select
                                    labelId="paper-size-label"
                                    value={paperSize}
                                    label={t('PrintPreviewModal.PaperSize')}
                                    onChange={(e) => setPaperSize(e.target.value)}
                                >
                                    <MenuItem value="A4">A4</MenuItem>
                                    <MenuItem value="A3">A3</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="orientation-label">
                                    {t('PrintPreviewModal.Orientation')}
                                </InputLabel>
                                <Select
                                    labelId="orientation-label"
                                    value={orientation}
                                    label={t('PrintPreviewModal.Orientation')}
                                    onChange={(e) => setOrientation(e.target.value)}
                                >
                                    <MenuItem value="portrait">
                                        {t('PrintPreviewModal.Portrait')}
                                    </MenuItem>
                                    <MenuItem value="landscape">
                                        {t('PrintPreviewModal.Landscape')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        {/* The actual preview area that we will also print */}
                        <div
                            style={{
                                width: previewWidth + 'px',
                                height: previewHeight + 'px',
                                overflow: 'auto',
                                marginTop: '20px',
                                border: '1px solid #ccc',
                                position: 'relative',
                            }}
                        >
                            {/* This ref is what gets printed by react-to-print */}
                            <div ref={printAreaRef} style={{ width: '100%', height: '100%' }}>
                                {/* Inject printing style at runtime */}
                                <style type="text/css" media="print">
                                    {printStyle}
                                </style>

                                {/* Your grid layout preview */}
                                {!isInitializing && (
                                    <ReactGridLayout
                                        layout={internalLayout}
                                        cols={cols}
                                        rowHeight={rowHeight}
                                        width={cols * rowHeight}
                                        // or something that fits your columns * rowHeight
                                        isDraggable={false}
                                        isResizable={false}
                                        style={{
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        {filteredItems.map((item) => (
                                            <div
                                                key={item.instanceId}
                                                data-grid={internalLayout.find(
                                                    (l) => l.i === item.instanceId
                                                )}
                                            >
                                                {item.type === 'box' ? (
                                                    <BoxItem
                                                        box={item}
                                                        isPreview={true}
                                                        showFoodPortions={showFoodPortions}
                                                        foodSettings={foodSettings}
                                                    />
                                                ) : (
                                                    <RoomItem room={item} isPreview={true} />
                                                )}
                                            </div>
                                        ))}
                                    </ReactGridLayout>
                                )}
                            </div>
                        </div>

                        {/* Print button */}
                        <Button
                            variant="contained"
                            sx={{ mt: 2 }}
                            onClick={handlePrint}
                        >
                            {t('PrintPreviewModal.Print')}
                        </Button>
                    </>
                ) : (
                    // If no existing layout, let user pick location/building
                    <>
                        <Typography variant="body1">
                            {t('PrintPreviewModal.PleaseSelectLocationBuilding')}
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <Select
                                value={selectedLocation}
                                onChange={handleLocationChange}
                                displayEmpty
                                fullWidth
                                sx={{ marginBottom: 2 }}
                            >
                                <MenuItem value="" disabled>
                                    {t('PrintPreviewModal.SelectLocation')}
                                </MenuItem>
                                {locations.map((location) => (
                                    <MenuItem key={location.id} value={location.id}>
                                        {location.name}
                                    </MenuItem>
                                ))}
                            </Select>

                            {selectedLocation && (
                                <Select
                                    value={selectedBuilding}
                                    onChange={handleBuildingChange}
                                    displayEmpty
                                    fullWidth
                                    sx={{ marginBottom: 2 }}
                                >
                                    <MenuItem value="" disabled>
                                        {t('PrintPreviewModal.SelectBuilding')}
                                    </MenuItem>
                                    {buildings.map((building) => (
                                        <MenuItem key={building.id} value={building.id}>
                                            {building.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default PrintPreviewModal;
