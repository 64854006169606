// src/components/HorseInfoPageTabs/ServicesType.js

import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Accordion, AccordionSummary, AccordionDetails, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { parseISO } from 'date-fns';


import { useAuth } from '../../context/AuthContext';
import { useDateLocale } from '../../hooks/useDateLocale';
import { fetchConnectedServiceTypes } from '../../services/serviceTypesService';  // Your service
// Your custom hook



const ServicesType = ({ horse, setHorse }) => {
    const { t } = useTranslation();
    const { apiKey, organizationId, token: jwtToken } = useAuth();
    const { dateLocale } = useDateLocale();
    const [serviceTypes, setServiceTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedServices, setSelectedServices] = useState({});
    const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);

    useEffect(() => {
        const fetchServiceTypesData = async () => {
            try {
                const serviceTypesData = await fetchConnectedServiceTypes(apiKey, organizationId, jwtToken);
                setServiceTypes(serviceTypesData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching service types:', error);
            }
        };

        setSelectedServiceTypes(horse.services || []);

        fetchServiceTypesData();
    }, [apiKey, organizationId, jwtToken]);

    const addServiceType = () => {
        if (selectedServiceTypes.length < serviceTypes.length) {
            setSelectedServiceTypes(prev => [...prev, { serviceId: '' }]);
        } else {
            alert('No more service types available to add.');
        }
    };

    const handleServiceChange = (index, serviceId) => {
        const updatedServiceTypes = [...selectedServiceTypes];
        updatedServiceTypes[index].serviceId = serviceId;
        setSelectedServiceTypes(updatedServiceTypes);
        setHorse({ ...horse, services: updatedServiceTypes });
    };

    const removeServiceType = (index) => {
        const updatedServiceTypes = selectedServiceTypes.filter((_, i) => i !== index);
        setSelectedServiceTypes(updatedServiceTypes);
        setHorse({ ...horse, services: updatedServiceTypes });
    };


    return (
        <Box>
            {selectedServiceTypes.map((selectedType, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        <Typography sx={{ flex: 1 }}>
                            {serviceTypes.find(type => type.id === selectedType.serviceId)?.name}
                        </Typography>
                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevent accordion toggling
                                    removeServiceType(index);
                                }}
                                color="error"
                                size="small"
                                edge="end"
                                sx={{ transform: 'none' }} // Ensure it does not rotate
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl fullWidth>
                            <InputLabel>Select Service</InputLabel>
                            <Select
                                value={selectedType.serviceId}
                                onChange={(e) => handleServiceChange(index, e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {serviceTypes.map(option => (
                                    <MenuItem key={option.id} value={option.id} disabled={selectedServiceTypes.some(st => st.serviceId === option.id)}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Button startIcon={<AddIcon />}
                onClick={addServiceType}
                sx={{ mt: 2 }}
                disabled={selectedServiceTypes.length === serviceTypes.length}
            >
                Add Service Type
            </Button>
        </Box>
    );
};

export default ServicesType;