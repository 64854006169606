// App.js
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { PermissionsProvider } from './context/PermissionsContext';
import AppRoutes from './Routes/AppRoutes';

// MSAL imports 
import { MsalProvider } from "@azure/msal-react";
import { setPolicyBasedOnUrl } from './context/setPolicyBasedOnUrl'; // Adjust the path as needed

// Import LocalizationProvider and date adapter
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

// Import your date locale hook or use your i18n library to get the current locale
import { useDateLocale } from './hooks/useDateLocale';

const msalInstance = setPolicyBasedOnUrl(); // Initialize MSAL instance with dynamic policy

function AppContent() {
  return (
    <AppRoutes />
  );
}

function App() {
  const dateLocale = useDateLocale(); // Get the locale for date localization

  return (
    <PermissionsProvider>
      <AuthProvider>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
              <AppContent />
            </LocalizationProvider>
          </BrowserRouter>
        </MsalProvider>
      </AuthProvider>
    </PermissionsProvider>
  );
}

export default App;
