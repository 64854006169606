// src/pages/FarrierVisitPage.js
import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    LinearProgress,
    Switch,
    FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FarrierVisitForm from '../components/FarrierVisitModal'; // Import your FarrierVisitForm
import { useAuth } from '../context/AuthContext';
import { fetchAllFarrierVisits } from '../services/FarrierVisitService'; // Import the service function for fetching farrier visits
import { getAllHorses, getHorsesByUserId } from '../services/horseService'; // Import horse service
import { fetchAllFerries } from '../services/FerryService'; // Import farrier service
import { useTranslation } from 'react-i18next';
import { useDateLocale } from '../hooks/useDateLocale';
import { format } from 'date-fns';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from '@mui/material/IconButton';
import GenericCardView from '../components/GenericCardView';
import GenericTableView from '../components/GenericTableView';
import { useTheme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { usePreferences } from '../context/UserPreferencesContext';

const FarrierVisitPage = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [farrierVisits, setFarrierVisits] = useState([]); // To store farrier visits
    const [horses, setHorses] = useState([]);
    const [farriers, setFarriers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { apiKey, organizationId, token, userType, userEmail, ownerId, userId } = useAuth();
    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [viewMode, setViewMode] = useState(isMobile ? 'card' : 'table');
    const [viewOwnVisits, setViewOwnVisits] = useState(true);
    const [ownerHorses, setOwnerHorses] = useState([]);
    const { devicePreferences, updateDevicePreferences } = usePreferences();



    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const farrierVisitData = await fetchAllFarrierVisits(apiKey, organizationId, token);
                setFarrierVisits(farrierVisitData);

                let horseData;
                if (userType === 'admin') {
                    horseData = await getAllHorses(apiKey, organizationId, token);
                } else {
                    horseData = await getHorsesByUserId(userId, apiKey, organizationId, token);
                }
                setHorses(horseData);
                setOwnerHorses(horseData.filter(horse => horse.ownerId === ownerId));

                const farrierData = await fetchAllFerries(apiKey, organizationId, token, ownerId);
                setFarriers(farrierData);

            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [apiKey, organizationId, token, isModalOpen]);

    // Update local state when context updates
    useEffect(() => {
        setViewMode(devicePreferences.viewMode);
    }, [devicePreferences.viewMode]);


    const farrierServiceCategories = [
        {
            category: t('farrierVisits.serviceCategories.basicServices'),
            services: [
                { key: 'barehoof', label: t('farrierVisits.services.barehoof') },
                { key: 'lostShoe', label: t('farrierVisits.services.lostShoe') },
            ],
        },
        {
            category: t('farrierVisits.serviceCategories.shoeingOptions'),
            services: [
                { key: '2horseshoes', label: t('farrierVisits.services.2horseshoes') },
                { key: '4horseshoes', label: t('farrierVisits.services.4horseshoes') },
                { key: 'specialShoeing', label: t('farrierVisits.services.specialShoeing') },
            ],
        },
    ];


    const getServiceLabel = (serviceKey) => {
        for (const category of farrierServiceCategories) {
            const service = category.services.find(s => s.key === serviceKey);
            if (service) {
                return service.label;
            }
        }
        return 'Unknown Service';
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentRecord(null); // Reset the current record when the modal is closed
    };

    const handleAddVisit = () => {
        setCurrentRecord(null); // No current record for adding a new visit
        setModalOpen(true);
    };


    const getHorseNameById = (horseId) => {
        const horse = horses.find(h => h.id === horseId);
        return horse ? horse.name : 'Unknown Horse';
    };

    const handleEditFarrierVisit = (id) => {
        const record = farrierVisits.find(v => v.id === id);
        setCurrentRecord(record);
        setModalOpen(true);
    };

    const toggleViewMode = () => {
        updateDevicePreferences('viewMode', viewMode === 'table' ? 'card' : 'table');
    };


    const transformData = (visitsData) => {
        return {
            headers: [{
                key: 'horseName', label: t('farrierVisits.TableHeaders.horseName'), display: { value: true, chipColor: false }
            }, {
                key: 'dateOfVisit', label: t('farrierVisits.TableHeaders.dateOfVisit'), display: { value: true, chipColor: false }
            }, {
                key: 'service', label: t('farrierVisits.TableHeaders.service'), display: { value: true, chipColor: false }
            }],
            rows: visitsData.map(visit => ({
                id: visit.id,
                horseName: {
                    value: getHorseNameById(visit.horseId),
                    icon: null
                },
                dateOfVisit: {
                    value: format(new Date(visit.dateOfVisit), 'P', { locale: dateLocale }),
                    icon: null
                },
                service: {
                    value: getServiceLabel(visit.service),
                    icon: null
                }
            }))
        };
    };


    const filteredVisits = useMemo(() => {
        if (!viewOwnVisits) {
            return farrierVisits; // return all visits if the toggle is set to view all
        }
        // Filter to return only the user's visits if the toggle is off
        return farrierVisits.filter(visit => ownerHorses.some(horse => horse.id === visit.horseId));
    }, [viewOwnVisits, farrierVisits, ownerHorses]);


    return (

        <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
            <div>
                <Typography variant="h4" gutterBottom>
                    {t('farrierVisits.Page.title')}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 4, mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddVisit}
                    >
                        {t('farrierVisits.Page.addNewVisit')}
                    </Button>
                    <FormControlLabel
                        control={<
                            Switch
                            checked={viewOwnVisits}
                            onChange={() => setViewOwnVisits(!viewOwnVisits)}
                            name="viewMode"
                            color="primary"
                        />
                        }
                        label={t('farrierVisits.Page.viewOwnVisits')}
                    />
                    <IconButton onClick={toggleViewMode} color="primary">
                        {viewMode === 'table' ? <ViewModuleIcon /> : <ViewListIcon />}
                    </IconButton>
                </Box>

                {isLoading ? (
                    <LinearProgress />
                ) : viewMode === 'table' ? (
                    <GenericTableView structure={transformData(filteredVisits)} onClick={handleEditFarrierVisit} />
                ) : (
                    <GenericCardView structure={transformData(filteredVisits)} onClick={handleEditFarrierVisit} />
                )}
                <FarrierVisitForm
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    record={currentRecord}
                    horses={horses}
                    farriers={farriers}
                />
            </div>
        </Paper>

    );
};

export default FarrierVisitPage;
