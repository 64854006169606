import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Button,
  Grid,
  Modal,
  TextField,
  MenuItem,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CalendarView from '../components/CalendarView';
import SnackbarAlert from '../components/SnackbarAlert';

import { useAuth } from '../context/AuthContext';
import { getCalendarEvents } from '../services/buildingCalenderViewService';
import { fetchLocationSettings } from '../services/LocationSettingsService';
// If you create separate services for creating/updating events, import them here:
// import { createCombinedEvent, updateCombinedEvent, deleteCombinedEvent } from '../../services/calendarEventsService';

// React Big Calendar localizer
import { momentLocalizer } from 'react-big-calendar';

// Force Monday as the first day of the week (optional)
moment.updateLocale('en', {
  week: {
    dow: 1, // Monday
  },
});
const localizer = momentLocalizer(moment);

const BuildingCalenderView = () => {
  const { t } = useTranslation();
  const { token, apiKey, organizationId, userId } = useAuth();

  const navigate = useNavigate();

  // --- State for calendar events ---
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState('month');
  const [currentDate, setCurrentDate] = useState(new Date());

  // --- State for location/building data ---
  const [locations, setLocations] = useState([]); 
  const [arenas, setArenas] = useState([]);         // Only buildings that are "riding arenas"
  
  // --- State for UI interactions ---
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);

  // --- Minimal event form data for create/edit ---
  const [formData, setFormData] = useState({
    title: '',
    start: null,
    end: null,
    buildingId: '', // or locationId
    // ... add more fields as necessary
  });

  // Calendar formats if you want 24h style, etc.
  const formats = {
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'HH:mm', culture) + ' – ' + localizer.format(end, 'HH:mm', culture),
    agendaTimeFormat: 'HH:mm',
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'MMM DD', culture) + ' – ' + localizer.format(end, 'MMM DD', culture),
    dayHeaderFormat: 'dddd, MMMM DD, YYYY',
  };

  // ------------------------------------------------------------------------
  // 1) Fetch initial calendar data (combined events) whenever date/view changes
  // ------------------------------------------------------------------------
  useEffect(() => {
    loadEvents(currentDate, currentView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, currentView]);

  const loadEvents = async (date, view) => {
    try {
      // Build date range based on the view
      let startDate, endDate;
      if (view === 'month') {
        startDate = moment(date).startOf('month').toISOString();
        endDate = moment(date).endOf('month').toISOString();
      } else if (view === 'week') {
        startDate = moment(date).startOf('week').toISOString();
        endDate = moment(date).endOf('week').toISOString();
      } else {
        // fallback if day or something else
        startDate = moment(date).startOf('day').toISOString();
        endDate = moment(date).endOf('day').toISOString();
      }

      const combinedEvents = await getCalendarEvents(apiKey, organizationId, token, {
        startDate,
        endDate,
        // If you want to filter by user or horse, pass more query params here
      });

      // Convert string dates to actual Date objects
      const mapped = combinedEvents.map(evt => ({
        ...evt,
        start: new Date(evt.start),
        end: new Date(evt.end),
      }));
      setEvents(mapped);
    } catch (error) {
      console.error('Error loading combined events:', error);
      setSnackbar({
        open: true,
        message: t('common.errorLoadingData'),
        severity: 'error',
      });
    }
  };

  // ------------------------------------------------------------------------
  // 2) Fetch location settings so we can pick a building (arena)
  // ------------------------------------------------------------------------
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const data = await fetchLocationSettings(apiKey, organizationId, token);
        setLocations(data);

        // Flatten out the building array from each location, and filter for "arena"
        const allArenas = [];
        data.forEach(loc => {
          // Each location has an array `buildings`
          loc.buildings.forEach(b => {
            // For example, if `b.type === 'arena'` or `b.isArena === true`, etc.
            if (b.type === 'arena') {
              allArenas.push({
                ...b,
                locationName: loc.name,
                locationId: loc.id,
              });
            }
          });
        });
        setArenas(allArenas);
      } catch (error) {
        console.error('Failed to fetch locations:', error);
      }
    };
    fetchLocations();
  }, [apiKey, organizationId, token]);

  // ------------------------------------------------------------------------
  // 3) Calendar event selection logic
  // ------------------------------------------------------------------------
  const handleSelectEvent = (event) => {
    // Open modal in edit mode
    setIsEditing(true);
    setEventToEdit(event);

    setFormData({
      title: event.title || '',
      start: event.start,
      end: event.end,
      buildingId: event.buildingId || '',
      // ... fill any other fields you store
    });

    setModalOpen(true);
  };

  const handleSelectSlot = (slotInfo) => {
    // Create mode
    setIsEditing(false);
    setEventToEdit(null);
    setSelectedSlot(slotInfo);

    // By default, set the start & end from the slot range
    setFormData({
      title: '',
      start: slotInfo.start,
      end: slotInfo.end,
      buildingId: '',
    });

    setModalOpen(true);
  };

  // ------------------------------------------------------------------------
  // 4) Create / Update logic
  //    (In this example, we are only stubbing these out; adapt to your actual service calls.)
  // ------------------------------------------------------------------------
  const handleSave = async () => {
    // If you have separate create & update endpoints, you’d call them here.
    // For example:
    /*
    if (!isEditing) {
      // create
      const newEvent = await createCombinedEvent({ ...formData }, apiKey, organizationId, token);
      setEvents(prev => [...prev, { ...newEvent, start: new Date(newEvent.start), end: new Date(newEvent.end) }]);
    } else {
      // update
      const updatedEvent = await updateCombinedEvent(eventToEdit.id, { ...formData }, apiKey, organizationId, token);
      setEvents(prev => prev.map(evt => (evt.id === updatedEvent.id ? { ...updatedEvent } : evt)));
    }
    */

    // For now, just close the modal and show a success message
    setSnackbar({
      open: true,
      message: t('common.changesSaved'),
      severity: 'success',
    });
    setModalOpen(false);
    setSelectedSlot(null);
    setIsEditing(false);
    setEventToEdit(null);
  };

  const handleDeleteEvent = async () => {
    // Example of a delete call:
    /*
    if (eventToEdit) {
      await deleteCombinedEvent(eventToEdit.id, apiKey, organizationId, token);
      setEvents(prev => prev.filter(evt => evt.id !== eventToEdit.id));
    }
    */
    setSnackbar({
      open: true,
      message: t('common.deletedSuccessfully'),
      severity: 'success',
    });
    setModalOpen(false);
    setEventToEdit(null);
    setIsEditing(false);
  };

  // ------------------------------------------------------------------------
  // 5) Optional styling for events in the calendar
  // ------------------------------------------------------------------------
  const eventStyleGetter = (event) => {
    // You can style by `event.type` (e.g., 'booking' vs 'activity')
    let style = {};
    if (event.type === 'booking') {
      style = { backgroundColor: '#64b5f6', color: 'white' };
    } else if (event.type === 'activity') {
      style = { backgroundColor: '#ffb74d', color: 'black' };
    }
    return { style };
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // ------------------------------------------------------------------------
  // RENDER
  // ------------------------------------------------------------------------
  return (
    <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('common.combinedCalendar')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CalendarView
            localizer={localizer}
            events={events}
            selectable
            views={['week', 'month']}
            view={currentView}
            onView={setCurrentView}
            onNavigate={(date) => setCurrentDate(date)}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            formats={formats}
            eventPropGetter={eventStyleGetter}
          />
        </Grid>
      </Grid>

      {/* 
        If you have a "Cancel" button or want to navigate away:
        <Button variant="outlined" onClick={() => navigate('/your-other-route')} sx={{ mt: 2 }}>
          {t('common.cancel')}
        </Button>
      */}

      <SnackbarAlert
        open={snackbar.open}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      {/* ------------------ MODAL for create/edit event ------------------ */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div
          style={{
            padding: '2rem',
            backgroundColor: '#fff',
            margin: '5% auto',
            maxWidth: 600,
            borderRadius: 8,
            boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
          }}
        >
          <Typography variant="h6" gutterBottom>
            {isEditing ? t('common.editEvent') : t('common.newEvent')}
          </Typography>

          <TextField
            label={t('common.title')}
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
          />

          {/* Building selection (arenas only) */}
          <TextField
            select
            label={t('common.selectArena')}
            value={formData.buildingId}
            onChange={(e) => setFormData({ ...formData, buildingId: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
          >
            {arenas.map((bld) => (
              <MenuItem key={bld.id} value={bld.id}>
                {bld.name} ({bld.locationName})
              </MenuItem>
            ))}
          </TextField>

          {/* Start Time */}
          <TextField
            type="datetime-local"
            label={t('common.start')}
            fullWidth
            value={moment(formData.start).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => {
              const dateVal = e.target.value ? new Date(e.target.value) : null;
              setFormData({ ...formData, start: dateVal });
            }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />

          {/* End Time */}
          <TextField
            type="datetime-local"
            label={t('common.end')}
            fullWidth
            value={moment(formData.end).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => {
              const dateVal = e.target.value ? new Date(e.target.value) : null;
              setFormData({ ...formData, end: dateVal });
            }}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                {t('common.save')}
              </Button>
            </Grid>
            {isEditing && (
              <Grid item xs={6}>
                <Button variant="outlined" color="error" fullWidth onClick={handleDeleteEvent}>
                  {t('common.delete')}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Modal>

      {/* Floating Action Button to open the modal (only if you want) */}
      <Fab
        color="primary"
        aria-label="add"
        onClick={() => {
          // “Add new event” flow if no slot is selected
          setIsEditing(false);
          setSelectedSlot(null);
          setEventToEdit(null);
          setFormData({
            title: '',
            start: null,
            end: null,
            buildingId: '',
          });
          setModalOpen(true);
        }}
        style={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>
    </Paper>
  );
};

export default BuildingCalenderView;
