// src/pages/expenseDetailsPage/ExpenseDetailsPage.js
import React from 'react';
import {
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  LinearProgress,
  IconButton,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useExpense } from './useExpense';
import { useAuth } from '../../context/AuthContext';
import ExpenseTable from './ExpenseTable';
import SuggestionModal from '../../components/SuggestionModal';

const ExpenseDetailsPage = () => {
  const { t } = useTranslation();
  const { expenseId } = useParams();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { apiKey, organizationId, token, userId } = useAuth();

  const {
    expenseData,
    handleRowChange,
    handleDatePickerChange,
    handleSubmit,
    handleHeaderInputChange,
    handleCheckboxChange,
    items,
    isEditMode,
    removeRow,
    addRow,
    loading,
    error,
    isCancelled,
    measureUnits,
    handleCancel,
    categories,
    suggestions,
    isSuggestionModalOpen,
    setIsSuggestionModalOpen,
    handleSaveSuggestions,
  } = useExpense(expenseId, apiKey, organizationId, token, userId, state);

  if (loading) {
    // While categories/items/etc. are still fetching
    return <LinearProgress />;
  }

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
      {error && (
        <Typography color="error">
          {error}
        </Typography>
      )}

      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <IconButton color="primary" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 1 }}>
          {expenseId === 'new'
            ? t('expenseRecording.createExpense')
            : t('expenseRecording.editExpense')}
        </Typography>
        {expenseId !== 'new' && isCancelled && (
          <Chip
            sx={{ ml: 2 }}
            icon={<WarningAmberIcon />}
            label={t('invoiceDetailPage.cancelled')}
            color="warning"
            variant="outlined"
          />
        )}
      </Box>

      {/* Main form */}
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('expenseRecording.expenseNumber')}
              name="expenseNumber"
              value={expenseData.expenseNumber}
              onChange={handleHeaderInputChange}
              margin="normal"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('expenseRecording.vendor')}
              name="vendor"
              value={expenseData.vendor}
              onChange={handleHeaderInputChange}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('expenseRecording.vendorDocumentNumber')}
              name="vendorDocumentNumber"
              value={expenseData.vendorDocumentNumber}
              onChange={handleHeaderInputChange}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={t('expenseRecording.expenseDate')}
              name="expenseDate"
              value={expenseData.expenseDate || null}
              onChange={handleDatePickerChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('expenseRecording.notes')}
              name="notes"
              value={expenseData.notes}
              onChange={handleHeaderInputChange}
              margin="normal"
              fullWidth
              multiline
              rows={2}
            />
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              checked={expenseData.isInvoice}
              onChange={handleCheckboxChange}
              name="isInvoice"
              color="primary"
            />
          }
          label={t('expenseRecording.isInvoice')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={expenseData.isDeliveryNote}
              onChange={handleCheckboxChange}
              name="isDeliveryNote"
              color="primary"
            />
          }
          label={t('expenseRecording.isDeliveryNote')}
        />

        {/* Expense Table */}
        <ExpenseTable
          rows={expenseData.rows}
          addRow={addRow}
          removeRow={removeRow}
          handleRowChange={handleRowChange}
          items={items}
          measureUnits={measureUnits}
          isEditMode={isEditMode}
          categories={categories}
        />

        {/* Totals */}
        <Box
          sx={{ mt: 3, mb: 2, display: 'flex', justifyContent: 'space-between' }}
        >
          <Typography variant="subtitle1">
            {`${t('expenseRecording.totalTaxes')}: ${expenseData.taxAmount}`}
          </Typography>
          <Typography variant="subtitle1">
            {`${t('expenseRecording.totalNetAmount')}: ${expenseData.totalNetAmount}`}
          </Typography>
          <Typography variant="subtitle1">
            {`${t('expenseRecording.totalExpense')}: ${expenseData.totalBruttoAmount}`}
          </Typography>
        </Box>

        {/* Action Buttons */}
        <Grid container spacing={2}>
          <Grid item>
            <Button type="submit" variant="contained" color="primary">
              {t('expenseRecording.saveButton')}
            </Button>
          </Grid>
          {expenseId !== 'new' && (
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={handleCancel}
                disabled={isCancelled}
              >
                {t('expenseRecording.cancelButton')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>

      {/* Suggestion Modal */}
      <SuggestionModal
        open={isSuggestionModalOpen}
        onClose={() => setIsSuggestionModalOpen(false)}
        suggestions={suggestions}
        onSave={handleSaveSuggestions}
      />
    </Paper>
  );
};

export default ExpenseDetailsPage;
