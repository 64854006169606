// src/pages/DeliveryPlanPrintDialog.js

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/printStyles.css';
import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';
import { useDateLocale } from '../../hooks/useDateLocale';


const DeliveryPlanPrintDialog = ({ open, onClose, deliveryPlan, vehicles, drivers }) => {
    const [currentDate, setCurrentDate] = useState('');
    const [printing, setPrinting] = useState(false);

    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    const handlePrint = async () => {
        try {
            const date = format(new Date(), 'P', { locale: dateLocale });
            setCurrentDate(date);
            setPrinting(true);
        } catch (error) {
            console.error('Error fetching current date:', error);
        }
    };

    useEffect(() => {
        if (printing && currentDate) {
            window.print();
            setPrinting(false);
        }
    }, [printing, currentDate]);

    const formatDate = (date) => {
        if (!date || !isValid(new Date(date))) return '';
        return format(new Date(date), 'P', { locale: dateLocale });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            aria-labelledby="delivery-plan-dialog-title"
        >
            <DialogTitle
                id="delivery-plan-dialog-title"
                sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
                <Typography variant="h6">{t('DeliveryPlanPrintDialog.title')}</Typography>
                <IconButton
                    aria-label={t('DeliveryPlanPrintDialog.closeButtonAriaLabel')}
                    onClick={onClose}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div id="print-content">
                    {deliveryPlan.routes.map((route, index) => {
                        const vehicle = vehicles.find((v) => v.id === route.vehicleId);
                        const driver = drivers.find((d) => d.id === route.driverId);

                        return (
                            <div key={route.id} className={index > 0 ? 'pagebreak' : ''}>
                                {/* Header Section */}
                                <div className="header-section" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                                    {/* Route Information on the left */}
                                    <div style={{ flex: 1 }}>
                                        <Typography variant="h5" gutterBottom>
                                            {route.name}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>{t('DeliveryPlanPrintDialog.vehicle')}:</strong> {vehicle ? vehicle.name : t('DeliveryPlanPrintDialog.unassigned')}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>{t('DeliveryPlanPrintDialog.driver')}:</strong> {driver ? driver.name : t('DeliveryPlanPrintDialog.unassigned')}
                                        </Typography>
                                    </div>

                                    {/* Plan Information on the right */}
                                    <div style={{ flex: 1, textAlign: 'right' }}>
                                        <Typography variant="h6" gutterBottom>
                                            {t('DeliveryPlanPrintDialog.deliveryPlanNumber')}: {deliveryPlan.deliveryPlanNumber || t('DeliveryPlanPrintDialog.notAvailable')}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {t('DeliveryPlanPrintDialog.deliveryDate')}: {formatDate(deliveryPlan.deliveryDate)}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            {t('DeliveryPlanPrintDialog.date')}: {currentDate}
                                        </Typography>
                                    </div>
                                </div>

                                {/* Table Section */}
                                <TableContainer component={Paper} className="table-container" style={{ marginBottom: '24px' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>{t('DeliveryPlanPrintDialog.customerName')}</strong></TableCell>
                                                <TableCell><strong>{t('DeliveryPlanPrintDialog.address')}</strong></TableCell>
                                                <TableCell><strong>{t('DeliveryPlanPrintDialog.items')}</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {route.salesOrders.map((order) => (
                                                <TableRow key={order.id}>
                                                    <TableCell>{order.customerName}</TableCell>
                                                    <TableCell>{order.address?.street}, {order.address?.city}</TableCell>
                                                    <TableCell>
                                                        {order.rows.map((row, idx) => (
                                                            <div key={row.rowKey || idx}>
                                                                {row.description} - <strong>{t('DeliveryPlanPrintDialog.qty')}:</strong> {row.quantity}
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('DeliveryPlanPrintDialog.closeButton')}</Button>
                <Button onClick={handlePrint} variant="contained" color="primary">
                    {t('DeliveryPlanPrintDialog.printButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeliveryPlanPrintDialog;
