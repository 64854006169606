// src/pages/ownerDetailsPage/OwnerInfoTab.js
import React from 'react';
import { TextField, Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


  // Your custom hook
import { usePermissions } from '../../context/PermissionsContext'; // <-- Add this line


const OwnerInfoTab = ({ owner, setOwner }) => {
    const { t } = useTranslation();  // Hook for translation
      // Hook for date locale
    const { entitlements } = usePermissions();

    const handleEntityTypeChange = (event) => {
        const { target: { value } } = event;
        setOwner({ ...owner, entityType: typeof value === 'string' ? value.split(',') : value });
    };

    // Check if "horse-management" feature is enabled
    const hasHorseManagement = entitlements.some(entitlement =>
        entitlement.features.some(feature => feature.name === 'horse-management' && feature.status === 'enabled')
    );

    // Determine the entitlement type
    const entitlement = entitlements.find(entitlement => entitlement.isActive) || {};
    const isEquistab = entitlement.internalName?.includes('equistab');

    return (
        // Add fields related to owner's information
        
            <Box >
                <FormControl fullWidth margin="normal">
                    <InputLabel>{t('OwnerDetails.typeOfOwner')}</InputLabel>
                    <Select
                        value={owner.ownerType}
                        onChange={(e) => setOwner({ ...owner, ownerType: e.target.value })}
                    >
                        <MenuItem value="private">{t('OwnerDetails.private')}</MenuItem>
                        <MenuItem value="company">{t('OwnerDetails.company')}</MenuItem>
                    </Select>
                </FormControl>
                {owner.ownerType === 'company' && (
                    <>
                        <TextField
                            fullWidth
                            label={t('OwnerDetails.companyName')}
                            value={owner.companyName}
                            onChange={(e) => setOwner({ ...owner, companyName: e.target.value })}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label={t('OwnerDetails.companyBRN')}
                            value={owner.companyBRN}
                            onChange={(e) => setOwner({ ...owner, companyBRN: e.target.value })}
                            margin="normal"
                        />
                    </>
                )}
                <FormControl fullWidth margin="normal">
                    <InputLabel>{t('OwnerDetails.entityType')}</InputLabel>
                    <Select
                        multiple
                        value={owner.entityType}
                        onChange={handleEntityTypeChange}
                        renderValue={(selected) =>
                            selected.map((value) => t(`OwnerDetails.${value}`)).join(', ')
                        }
                    >
                        {hasHorseManagement && (
                            <MenuItem value="owner">
                                <Checkbox checked={owner.entityType.indexOf('owner') > -1} />
                                <ListItemText primary={t('OwnerDetails.owner')} />
                            </MenuItem>

                        )}
                        <MenuItem value="customer">
                            <Checkbox checked={owner.entityType.indexOf('customer') > -1} />
                            <ListItemText primary={t('OwnerDetails.customer')} />
                        </MenuItem>
                        <MenuItem value="vendor">
                            <Checkbox checked={owner.entityType.indexOf('vendor') > -1} />
                            <ListItemText primary={t('OwnerDetails.vendor')} />
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    label={t('OwnerDetails.firstName')}
                    value={owner ? owner.firstname : ''}
                    onChange={(e) => {
                        setOwner({ ...owner, firstname: e.target.value });
                        console.log(e.target.value); // this should log the input value
                        console.log(owner); // this should log the updated state
                    }}
                    required
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label={t('OwnerDetails.SurName')}
                    value={owner ? owner.surname : ''}
                    onChange={(e) => setOwner({ ...owner, surname: e.target.value })}
                    required
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label={t('OwnerDetails.Email')}
                    value={owner ? owner.email : ''}
                    onChange={(e) => setOwner({ ...owner, email: e.target.value })}
                    required={isEquistab}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label={t('OwnerDetails.Number')}
                    value={owner ? owner.number : ''}
                    onChange={(e) => setOwner({ ...owner, number: e.target.value })}
                    margin="normal"
                />
                <DatePicker
                    label={t('OwnerDetails.Birthday')}
                    value={owner ? owner.birthday : null}
                    onChange={(newValue) => {
                        setOwner({ ...owner, birthday: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    margin="normal"
                />
            </Box>

        
    );
};

export default OwnerInfoTab;
