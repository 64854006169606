// src/pages/expenseDetailsPage/ExpenseTable.js
import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    TableContainer,
    Paper,
    Select,
    MenuItem,
    Collapse,
    Box,
    Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const ExpenseTable = ({
    rows,
    addRow,
    handleRowChange,
    items,
    measureUnits,
    removeRow,
    isEditMode,
    categories,
}) => {
    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRowExpansion = (index) => {
        setExpandedRow((prev) => (prev === index ? null : index));
    };

    return (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Net Price</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => {
                        const subcategoriesForRow =
                            categories.find((cat) => cat.categoryId === row.category)
                                ?.subcategories || [];

                        return (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <TableCell>
                                        <Autocomplete
                                            freeSolo
                                            options={items.map((item) => item.name)}
                                            value={row.description || ''}
                                            onInputChange={(e, newValue) =>
                                                handleRowChange(index, 'description', newValue)
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder="Item" fullWidth />
                                            )}
                                            disabled={isEditMode}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <Select
                                            value={
                                                categories.some(
                                                    (cat) => cat.categoryId === row.category
                                                )
                                                    ? row.category
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleRowChange(index, 'category', e.target.value)
                                            }
                                            fullWidth
                                            disabled={isEditMode}
                                        >
                                            <MenuItem value="">
                                                <em>-- Select --</em>
                                            </MenuItem>
                                            {categories.map((category) => (
                                                <MenuItem
                                                    key={category.categoryId}
                                                    value={category.categoryId}
                                                >
                                                    {category.categoryName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>

                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.quantity}
                                            onChange={(e) =>
                                                handleRowChange(index, 'quantity', e.target.value)
                                            }
                                            fullWidth
                                            disabled={isEditMode}
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.netPrice}
                                            onChange={(e) =>
                                                handleRowChange(index, 'netPrice', e.target.value)
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </TableCell>

                                    <TableCell>
                                        <IconButton onClick={() => removeRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton onClick={() => toggleRowExpansion(index)}>
                                            {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell
                                        style={{ paddingBottom: 0, paddingTop: 0 }}
                                        colSpan={5}
                                    >
                                        <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                            <Box margin={2}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Additional Details
                                                </Typography>
                                                <Table size="small" aria-label="details">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>Subcategory</TableCell>
                                                            <TableCell>
                                                                <Select
                                                                    value={
                                                                        subcategoriesForRow.some(
                                                                            (sub) =>
                                                                                sub.subcategoryId === row.subcategory
                                                                        )
                                                                            ? row.subcategory
                                                                            : ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'subcategory', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled={isEditMode}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>-- Select --</em>
                                                                    </MenuItem>
                                                                    {subcategoriesForRow.map((subcategory) => (
                                                                        <MenuItem
                                                                            key={subcategory.subcategoryId}
                                                                            value={subcategory.subcategoryId}
                                                                        >
                                                                            {subcategory.subcategoryName}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell>Measure Unit</TableCell>
                                                            <TableCell>
                                                                <Select
                                                                    value={
                                                                        measureUnits.some((mu) => mu.id === row.measureUnit)
                                                                            ? row.measureUnit
                                                                            : ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'measureUnit', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled={isEditMode}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>-- Select --</em>
                                                                    </MenuItem>
                                                                    {measureUnits.map((mu) => (
                                                                        <MenuItem key={mu.id} value={mu.id}>
                                                                            {mu.nameLong}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell>Tax Rate</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    type="number"
                                                                    value={row.taxRate}
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'taxRate', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled={isEditMode}
                                                                />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell>Discount Percentage</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    type="number"
                                                                    value={row.discountPercentage}
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'discountPercentage', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled={isEditMode}
                                                                />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell>Discount Amount</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    type="number"
                                                                    value={row.discountAmount}
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'discountAmount', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled={isEditMode}
                                                                />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell>Unit Brutto Amount</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    type="number"
                                                                    value={row.bruttoPrice}
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'bruttoPrice', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled={isEditMode}
                                                                />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell>Row Total</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    type="number"
                                                                    value={row.rowTotal}
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'rowTotal', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled
                                                                />
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell>Notes</TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    value={row.notes}
                                                                    onChange={(e) =>
                                                                        handleRowChange(index, 'notes', e.target.value)
                                                                    }
                                                                    fullWidth
                                                                    disabled={isEditMode}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        );
                    })}
                    {!isEditMode && (
                        <TableRow>
                            <TableCell colSpan={5} style={{ textAlign: 'right' }}>
                                <IconButton color="primary" onClick={addRow}>
                                    <AddCircleIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ExpenseTable;
