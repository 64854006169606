import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import { useDateLocale } from '../../../hooks/useDateLocale';
import { format } from 'date-fns';



const BusiestTimesLineChart = ({ titleKey, data, date, prevDay, nextDay }) => {
    const { t } = useTranslation();
    const dateLocale = useDateLocale();

    // Check if data is empty or no activities found
    const hasActivities = data && Object.keys(data).length > 0;

    const transformedData = hasActivities
        ? Array.from({ length: 24 }, (_, hour) => {
            const entry = { hour };
            Object.keys(data).forEach(spaceName => {
                entry[spaceName] = data[spaceName].times[hour].count;
            });
            return entry;
        })
        : [];

    const dateToShow = format(new Date(date), 'P', { locale: dateLocale });
    function getLineColor(index) {
        // Simple function to return a color based on index. Customize as needed.
        const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'];
        return colors[index % colors.length];
    }
    return (
        
            <Grid item xs={12} md={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">
                            {t(titleKey)}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" width="100%" my={2}>
                            <IconButton onClick={prevDay}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {dateToShow}
                            </Typography>
                            <IconButton onClick={nextDay}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Box>
                        {hasActivities ? (
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart width={1000} height={300} data={transformedData}>
                                    <XAxis dataKey="hour" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    {Object.keys(data).map((spaceName, index) => (
                                        <Line
                                            key={spaceName}
                                            type="monotone"
                                            dataKey={spaceName}
                                            stroke={getLineColor(index)}
                                            name={`${spaceName} (Total: ${data[spaceName].totalCount})`}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        ) : (
                            <Typography variant="h6" align="center">
                                No activities found.
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        
    );
};

BusiestTimesLineChart.propTypes = {
    titleKey: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    prevDay: PropTypes.func.isRequired,
    nextDay: PropTypes.func.isRequired,
};

export default BusiestTimesLineChart;
