// src/components/FarmDesigner/FarmLayout.js
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Button, Typography, Box } from '@mui/material';
import BoxItem from './BoxItem';
import RoomItem from './RoomItem';
import RoomCreationModal from './RoomCreationModal';
import './FarmLayout.css';
import { fetchAllBoxTypes } from '../../services/BoxService';
import { useAuth } from '../../context/AuthContext';
import { fetchFarmLayout, saveFarmLayout, deleteFarmLayout } from '../../services/FarmLayoutService';
import PrintPreviewModal from './PrintPreviewModal';
import { useTranslation } from 'react-i18next';

const ReactGridLayout = WidthProvider(RGL);

const FarmLayout = React.memo(
    ({ locationId, buildingId, buildingWidth, buildingLength, onBoxTypesChange }) => {
        const { t } = useTranslation();
        const { apiKey, organizationId, token: jwtToken } = useAuth();

        const [boxes, setBoxes] = useState([]);
        const [boxTypes, setBoxTypes] = useState([]);
        const [rooms, setRooms] = useState([]);
        const [layout, setLayout] = useState([]);
        const [layoutId, setLayoutId] = useState(null);
        const [isInitializing, setIsInitializing] = useState(true);
        const [isLayoutNotFound, setIsLayoutNotFound] = useState(false);
        const [isCreatingLayout, setIsCreatingLayout] = useState(false);

        const [boxCount, setBoxCount] = useState(0);
        const [roomCount, setRoomCount] = useState(0);

        // For printing:
        const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

        // For room creation modal:
        const [isModalOpen, setIsModalOpen] = useState(false);

        // ------------
        //  LOAD LOGIC
        // ------------
        const useLoadBoxTypes = (apiKey, organizationId, jwtToken, locationId, buildingId) => {
            useEffect(() => {
                const loadBoxTypes = async () => {
                    try {
                        const data = await fetchAllBoxTypes(apiKey, organizationId, jwtToken);
                        const validBoxTypes = data.filter(
                            (box) =>
                                box.locationId === locationId && box.buildingId === buildingId
                        );
                        setBoxTypes(validBoxTypes);
                    } catch (err) {
                        console.error('Error fetching box types', err);
                    }
                };
                if (locationId && buildingId) {
                    loadBoxTypes();
                }
            }, [apiKey, organizationId, jwtToken, locationId, buildingId]);
        };
        useLoadBoxTypes(apiKey, organizationId, jwtToken, locationId, buildingId);

        useEffect(() => {
            const loadLayout = async () => {
                try {
                    const data = await fetchFarmLayout(apiKey, organizationId, jwtToken, locationId, buildingId);
                    if (data && data.layoutData && data.layoutData.layout.length > 0) {
                        const loadedBoxes = data.layoutData.boxes || [];
                        const loadedRooms = data.layoutData.rooms || [];
                        setLayoutId(data.id);
                        setLayout(data.layoutData.layout);
                        setBoxes(
                            loadedBoxes.map((box) => ({
                                ...box,
                                width: Number(box.width) || 1,
                                length: Number(box.length) || 1,
                            }))
                        );
                        setRooms(
                            loadedRooms.map((room) => ({
                                ...room,
                                width: Number(room.width) || 1,
                                length: Number(room.length) || 1,
                            }))
                        );
                        setBoxCount(loadedBoxes.length);
                        setRoomCount(loadedRooms.length);
                    } else {
                        setIsLayoutNotFound(true);
                    }
                } catch (error) {
                    console.error('Error fetching farm layout', error);
                    if (error.response?.status === 404) {
                        setIsLayoutNotFound(true);
                    }
                } finally {
                    setIsInitializing(false);
                }
            };

            if (locationId && buildingId) {
                loadLayout();
            }
        }, [locationId, buildingId, apiKey, organizationId, jwtToken]);

        // -------------
        // SAVE LOGIC
        // -------------
        const saveLayout = useCallback(
            async (dataToSave) => {
                try {
                    if (!dataToSave.layoutData || dataToSave.layoutData.layout.length === 0) {
                        console.error('No layout data to save');
                        return;
                    }

                    const savedLayout = await saveFarmLayout(apiKey, organizationId, jwtToken, dataToSave);
                    if (!layoutId && savedLayout.id) {
                        setLayoutId(savedLayout.id);
                    }
                    console.log(t('FarmLayout.LayoutSavedSuccessfully'));
                } catch (error) {
                    console.error(t('FarmLayout.ErrorSavingLayout'), error);
                }
            },
            [layoutId, apiKey, organizationId, jwtToken, locationId, buildingId, t]
        );

        // -------------
        //  HANDLERS
        // -------------
        const onLayoutChange = useCallback(
            async (newLayout) => {
                setLayout(newLayout);
                const updatedLayoutData = {
                    layout: newLayout,
                    boxes: boxes,
                    rooms: rooms,
                };
                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const handleHorseDrop = useCallback(
            async (instanceId, horse) => {
                const updatedBoxes = boxes.map((box) =>
                    box.instanceId === instanceId ? { ...box, horse } : box
                );
                setBoxes(updatedBoxes);
                const updatedLayoutData = {
                    layout: layout,
                    boxes: updatedBoxes,
                    rooms: rooms,
                };
                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, layout, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const handleHorseRemove = useCallback(
            async (instanceId) => {
                const updatedBoxes = boxes.map((box) =>
                    box.instanceId === instanceId ? { ...box, horse: null } : box
                );
                setBoxes(updatedBoxes);
                const updatedLayoutData = {
                    layout: layout,
                    boxes: updatedBoxes,
                    rooms: rooms,
                };
                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, layout, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const handleRoomRemove = useCallback(
            async (instanceId) => {
                const updatedRooms = rooms.filter((room) => room.instanceId !== instanceId);
                setRooms(updatedRooms);
                const updatedLayoutData = {
                    layout: layout,
                    boxes: boxes,
                    rooms: updatedRooms,
                };
                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [rooms, boxes, layout, saveLayout, layoutId, locationId, buildingId]
        );

        const handleCreateRoom = useCallback(
            async (newRoom) => {
                const roomWithLocation = {
                    ...newRoom,
                    amount: '1',
                    locationId,
                    buildingId,
                    instanceId: `room-${Date.now()}`,
                    id: `room-${Date.now()}`,
                    type: 'room',
                    width: Number(newRoom.width) || 1,
                    length: Number(newRoom.length) || 1,
                };
                const updatedRooms = [...rooms, roomWithLocation];
                setRooms(updatedRooms);
                setRoomCount((prev) => prev + 1);

                const updatedLayoutData = {
                    layout: layout,
                    boxes: boxes,
                    rooms: updatedRooms,
                };
                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [locationId, buildingId, rooms, boxes, layout, saveLayout, layoutId]
        );

        const handleResetLayout = useCallback(async () => {
            // Optional: adapt to your needs or keep your existing logic
            try {
                setBoxes([]);
                setRooms([]);
                setLayout([]);
                setBoxCount(0);
                setRoomCount(0);

                // Save an empty layout:
                await saveLayout({
                    id: layoutId,
                    layoutData: { layout: [], boxes: [], rooms: [] },
                    locationId,
                    buildingId,
                });
                console.log(t('FarmLayout.LayoutResetSuccessfully'));
            } catch (error) {
                console.error(t('FarmLayout.ErrorResettingLayout'), error);
            }
        }, [apiKey, organizationId, jwtToken, locationId, buildingId, t, layoutId, saveLayout]);

        // Create brand new layout from boxTypes if not found
        const handleCreateLayout = useCallback(async () => {
            setIsCreatingLayout(true);
            try {
                const initialBoxes = boxTypes.flatMap((box) =>
                    Array.from({ length: Number(box.amount) }, (_, index) => ({
                        ...box,
                        width: Number(box.width) || 1,
                        length: Number(box.length) || 1,
                        instanceId: `${box.id}-${index}`,
                        type: 'box',
                        horse: null,
                    }))
                );
                const buildingWidthMeters = Number(buildingWidth) || 12;
                let currentX = 0;
                let currentY = 0;
                let maxRowHeight = 0;

                const initialLayout = initialBoxes.map((box) => {
                    const boxWidth = Number(box.width) || 1;
                    const boxLength = Number(box.length) || 1;

                    if (currentX + boxWidth > buildingWidthMeters) {
                        currentX = 0;
                        currentY += maxRowHeight;
                        maxRowHeight = 0;
                    }

                    const layoutItem = {
                        i: box.instanceId,
                        x: currentX,
                        y: currentY,
                        w: boxWidth,
                        h: boxLength,
                        static: false,
                    };

                    currentX += boxWidth;
                    if (boxLength > maxRowHeight) {
                        maxRowHeight = boxLength;
                    }

                    return layoutItem;
                });

                const newLayoutData = {
                    layout: initialLayout,
                    boxes: initialBoxes,
                    rooms: [],
                };

                const savedLayout = await saveFarmLayout(apiKey, organizationId, jwtToken, {
                    layoutData: newLayoutData,
                    locationId,
                    buildingId,
                });

                setLayoutId(savedLayout.id);
                setLayout(initialLayout);
                setBoxes(initialBoxes);
                setBoxCount(initialBoxes.length);
                setIsLayoutNotFound(false);
            } catch (error) {
                console.error(t('FarmLayout.ErrorCreatingLayout'), error);
            } finally {
                setIsCreatingLayout(false);
            }
        }, [boxTypes, apiKey, organizationId, jwtToken, locationId, buildingId, t, buildingWidth]);

        // -----------
        //   RENDER
        // -----------
        // Filter items for the correct location/building
        const filteredItems = useMemo(() => {
            const validBoxes = boxes.filter(
                (box) => box.locationId === locationId && box.buildingId === buildingId
            );
            const validRooms = rooms.filter(
                (room) => room.locationId === locationId && room.buildingId === buildingId
            );
            return [...validBoxes, ...validRooms];
        }, [boxes, rooms, locationId, buildingId]);

        // Notify parent about which boxes exist for the horse filter
        useEffect(() => {
            onBoxTypesChange(filteredItems.filter((item) => item.type === 'box'));
        }, [filteredItems, onBoxTypesChange]);

        // For the grid:
        const buildingWidthMeters = Number(buildingWidth) || 12;
        const pixelsPerMeter = 30;

        // Room creation modal
        const openRoomCreationModal = () => setIsModalOpen(true);
        const closeRoomCreationModal = () => setIsModalOpen(false);

        // Print preview modal
        const openPrintModal = () => setIsPrintModalOpen(true);
        const closePrintModal = () => setIsPrintModalOpen(false);

        return (
            <>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        {t('FarmLayout.DesignLayout')}
                    </Typography>
                    <Box>
                        <Button variant="contained" onClick={openRoomCreationModal} sx={{ mr: 1 }}>
                            {t('FarmLayout.CreateNewRoom')}
                        </Button>
                        <Button variant="contained" onClick={openPrintModal} sx={{ mr: 1 }}>
                            {t('FarmLayout.Print')}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleResetLayout}
                        >
                            {t('FarmLayout.Reset')}
                        </Button>
                    </Box>
                </Box>

                <div
                    id="grid-container"
                    style={{
                        backgroundColor: '#f9f9f9',
                        position: 'relative',
                        boxSizing: 'border-box',
                        userSelect: 'none',
                    }}
                >
                    {isLayoutNotFound ? (
                        <Box textAlign="center" mt={4}>
                            <Typography variant="h6" gutterBottom>
                                {t('FarmLayout.LayoutNotFound')}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCreateLayout}
                                disabled={isCreatingLayout}
                            >
                                {isCreatingLayout
                                    ? t('FarmLayout.CreatingLayout')
                                    : t('FarmLayout.CreateLayout')}
                            </Button>
                        </Box>
                    ) : !isInitializing && layout.length > 0 ? (
                        <ReactGridLayout
                            className="layout"
                            layout={layout}
                            cols={Math.ceil(buildingWidthMeters)}
                            rowHeight={pixelsPerMeter}
                            width={buildingWidthMeters * pixelsPerMeter}
                            onLayoutChange={onLayoutChange}
                            useCSSTransforms={true}
                            isBounded={false}
                            draggableCancel=".non-draggable"
                        >
                            {filteredItems.map((item) => (
                                <div
                                    key={item.instanceId}
                                    data-grid={layout.find((l) => l.i === item.instanceId)}
                                >
                                    {item.type === 'box' ? (
                                        <BoxItem
                                            box={item}
                                            handleHorseDrop={handleHorseDrop}
                                            handleHorseRemove={handleHorseRemove}
                                        />
                                    ) : (
                                        <RoomItem
                                            room={item}
                                            handleRoomRemove={handleRoomRemove}
                                        />
                                    )}
                                </div>
                            ))}
                        </ReactGridLayout>
                    ) : (
                        !isInitializing && (
                            <Typography variant="body1">
                                {t('FarmLayout.PleaseSelectBuilding')}
                            </Typography>
                        )
                    )}
                </div>

                {/* PRINT PREVIEW MODAL */}
                <PrintPreviewModal
                    isOpen={isPrintModalOpen}
                    onClose={closePrintModal}
                    layout={layout}
                    boxes={boxes}
                    rooms={rooms}
                    isInitializing={isInitializing}
                    filteredItems={filteredItems}
                />

                {/* ROOM CREATION MODAL */}
                <RoomCreationModal
                    isOpen={isModalOpen}
                    onClose={closeRoomCreationModal}
                    onCreateRoom={handleCreateRoom}
                />
            </>
        );
    }
);

export default FarmLayout;
