import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, Switch, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Autocomplete, Paper, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { useTeacher } from './useTeacher';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LinearProgress } from '@mui/material';
import { useDateLocale } from '../../../hooks/useDateLocale';
import DeleteIcon from '@mui/icons-material/Delete';

const defaultDaysIndex = [1, 2, 3, 4, 5, 6, 0]; // Adjust based on your needs


const TeacherSettings = () => {
  

  const {
    teachers,
    users,
    loading,
    handleTimeChange,
    toggleAvailabilityConfig,
    handleAddTeacher,
    handleSave,
    handleAutocompleteChange,
    handleRemoveTeacher,
  }
    = useTeacher();

  return (
    
      <Paper>
        <Box sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>Teacher Settings</Typography>
          {loading && <LinearProgress />}
          {teachers.map((teacher, index) => (
            <Accordion key={teacher.id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{teacher.name || `Teacher ${index + 1}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      key={teacher.id}
                      freeSolo
                      options={users}
                      getOptionLabel={(option) => option.firstname + ' ' + option.surname}
                      renderInput={(params) => <TextField {...params} label="User" />}
                      onChange={(event, newValue) => handleAutocompleteChange(teacher.id, index, newValue)}
                      onInputChange={(event, newInputValue) => handleAutocompleteChange(teacher.id, index, newInputValue, true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Switch checked={teacher.configureAvailability} onChange={() => toggleAvailabilityConfig(teacher.id, index)} />}
                      label="Configure Availability"
                    />
                  </Grid>
                  {teacher.configureAvailability && defaultDaysIndex.map(dayNumber => (
                    <React.Fragment key={dayNumber}>
                      <Grid item xs={6}>
                        <TimePicker
                          label={`Day ${dayNumber} From`} // Adjust label as needed
                          value={teacher.availability[dayNumber]?.from ? new Date(teacher.availability[dayNumber].from) : null}
                          onChange={(newValue) => handleTimeChange(teacher.id, index, dayNumber, newValue, 'from')}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TimePicker
                          label={`Day ${dayNumber} To`} // Adjust label as needed
                          value={teacher.availability[dayNumber]?.to ? new Date(teacher.availability[dayNumber].to) : null}
                          onChange={(newValue) => handleTimeChange(teacher.id, index, dayNumber, newValue, 'to')}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item>
                    <Tooltip title="Remove Teacher">
                      <IconButton onClick={() => handleRemoveTeacher(index)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton onClick={handleAddTeacher} disabled={loading}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={loading}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    
  );
};

export default TeacherSettings;
