// src/pages/expenseDetailsPage/useExpense.js

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { getAllItems } from '../../services/itemService';
import {
  fetchExpenseById,
  saveExpense,
  updateExpense,
} from '../../services/expenseService';
import { useSnackbar } from './useSnackbar';
import { fetchMeasureUnits } from '../../services/measureUnitsService';
import { fetchCategories } from '../../services/categoryService';

/**
 * Helper to map data from "fromDocument" into your standard expenseData format.
 */
function mapFromDocumentToExpenseData(expenseData, fromDocument) {
  return {
    ...expenseData,
    id: '',
    expenseNumber: '',
    expenseDate: fromDocument.expenseDate
      ? new Date(fromDocument.expenseDate)
      : expenseData.expenseDate,
    totalBruttoAmount:
      fromDocument.totalBruttoAmount || expenseData.totalBruttoAmount,
    totalNetAmount: fromDocument.totalNetAmount || expenseData.totalNetAmount,
    taxAmount: fromDocument.taxAmount || expenseData.taxAmount,
    vendor: fromDocument.vendor || expenseData.vendor,
    vendorDocumentNumber:
      fromDocument.expenseNumber || expenseData.vendorDocumentNumber,
    isInvoice:
      Boolean(fromDocument.isInvoice) || Boolean(expenseData.isInvoice),
    isDeliveryNote:
      Boolean(fromDocument.isDeliveryNote) ||
      Boolean(expenseData.isDeliveryNote),
    notes: fromDocument.notes || expenseData.notes,
    rows: fromDocument.rows?.map((row) => ({
      itemId: row.itemId, // may be empty if new
      description: row.itemDescription,
      quantity: row.quantity,
      measureUnit: row.measureUnitId, // may be empty if new
      category: row.categoryId, // may be empty if new
      subcategory: row.subcategoryId, // may be empty if new
      netPrice: row.netPrice,
      taxRate: row.taxRate,
      bruttoPrice: row.bruttoPrice,
      discountPercentage: row.discountPercentage || 0,
      discountAmount: row.discountAmount || 0,
      rowTotal: row.rowTotal,
      rowNumber: parseInt(row.rowNumber, 10) || 0,
      notes: row.item + ' ' + row.measureUnit + ' ' + row.notes,
    })),
  };
}

export const useExpense = (
  expenseId,
  apiKey,
  organizationId,
  jwtToken,
  userId,
  state
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [measureUnits, setMeasureUnits] = useState([]);
  const isEditMode = expenseId !== 'new';
  const { openSnackbar, SnackbarComponent } = useSnackbar();
  const [isCancelled, setIsCancelled] = useState(false);

  // Suggestions from the scanned doc
  const [suggestions, setSuggestions] = useState(null);
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = useState(false);

  const initialExpenseData = {
    id: '',
    expenseNumber: '',
    vendorId: '',
    vendor: '',
    vendorDocumentNumber: '',
    notes: '',
    cancel: false,
    isInvoice: false,
    isDeliveryNote: false,
    expenseDate: formatISO(new Date()),
    totalBruttoAmount: 0,
    totalNetAmount: 0,
    taxAmount: 0,
    rows: [],
  };

  const [expenseData, setExpenseData] = useState(initialExpenseData);

  // Handle checkboxes
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setExpenseData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const calculateTotals = () => {
    let totalExpense = 0;
    let totalTaxes = 0;
    let totalNetAmount = 0;

    expenseData.rows.forEach((row) => {
      const bruttoPrice = parseFloat(row.bruttoPrice) || 0;
      const quantity = parseFloat(row.quantity) || 0;
      const taxRate = parseFloat(row.taxRate) / 100 || 0;
      const discountPercentage = parseFloat(row.discountPercentage) / 100 || 0;
      const discountAmount = parseFloat(row.discountAmount) || 0;

      const discountedPrice = parseFloat(
        (bruttoPrice * (1 - discountPercentage) - discountAmount).toFixed(2)
      );
      const taxAmountForRow = parseFloat(
        ((discountedPrice - discountedPrice / (1 + taxRate)) * quantity).toFixed(
          2
        )
      );

      totalExpense += parseFloat((discountedPrice * quantity).toFixed(2));
      totalNetAmount += parseFloat(
        (discountedPrice * quantity - taxAmountForRow).toFixed(2)
      );
      totalTaxes += taxAmountForRow;
    });

    totalTaxes = parseFloat(totalTaxes.toFixed(2));
    totalNetAmount = parseFloat(totalNetAmount.toFixed(2));
    totalExpense = parseFloat(totalExpense.toFixed(2));

    return { totalExpense, totalTaxes, totalNetAmount };
  };

  const checkForCancellation = () => {
    if (expenseData.cancel) {
      setIsCancelled(true);
    } else {
      setIsCancelled(false);
    }
  };

  useEffect(() => {
    checkForCancellation();
    // eslint-disable-next-line
  }, [expenseData.id, apiKey, organizationId, jwtToken]);

  const handleCancel = async () => {
    try {
      await updateExpense(
        { ...expenseData, cancel: true },
        apiKey,
        organizationId,
        jwtToken,
        userId
      );
      setExpenseData({ ...expenseData, cancel: true });
      openSnackbar('Expense successfully cancelled', 'success');
      navigate('/expenses');
    } catch (error) {
      console.error('Error cancelling invoice:', error);
      openSnackbar('Failed to cancel invoice', 'error');
    }
  };

  const handleDatePickerChange = (date) => {
    setExpenseData({ ...expenseData, expenseDate: date });
  };

  // Recalculate totals whenever rows change
  useEffect(() => {
    const { totalExpense, totalTaxes, totalNetAmount } = calculateTotals();
    setExpenseData((prev) => ({
      ...prev,
      totalBruttoAmount: totalExpense,
      totalNetAmount,
      taxAmount: totalTaxes,
    }));
    // eslint-disable-next-line
  }, [expenseData.rows]);

  // Handling row changes
  const handleRowChange = (index, name, value) => {
    const updatedRows = [...expenseData.rows];
    let updatedRow = { ...updatedRows[index], [name]: value };

    // Recompute discount logic
    if (
      name === 'discountPercentage' ||
      name === 'discountAmount' ||
      name === 'quantity' ||
      name === 'bruttoPrice'
    ) {
      const bruttoPrice = parseFloat(updatedRow.bruttoPrice) || 0;
      let discountPercentage = parseFloat(updatedRow.discountPercentage) / 100 || 0;
      let discountAmount = parseFloat(updatedRow.discountAmount) || 0;
      const quantity = parseFloat(updatedRow.quantity) || 0;
      const taxRate = parseFloat(updatedRow.taxRate) / 100 || 0;

      if (name === 'discountPercentage') {
        discountAmount = parseFloat((bruttoPrice * discountPercentage).toFixed(2));
        updatedRow.discountAmount = discountAmount;
      } else if (name === 'discountAmount') {
        discountPercentage = parseFloat((discountAmount / bruttoPrice).toFixed(2));
        updatedRow.discountPercentage = (discountPercentage * 100).toFixed(2);
      }

      const currentDiscountedPrice = parseFloat(
        (bruttoPrice * (1 - discountPercentage) - discountAmount).toFixed(2)
      );
      const rowTotal = parseFloat((quantity * currentDiscountedPrice).toFixed(2));
      const netPrice = parseFloat(
        (currentDiscountedPrice / (1 + taxRate)).toFixed(2)
      );

      updatedRow = {
        ...updatedRow,
        rowTotal,
        netPrice,
      };
    }

    // If user chose item by name:
    if (name === 'description') {
      const selectedItem = items.find((item) => item.name === value);
      if (selectedItem) {
        updatedRow = {
          ...updatedRow,
          itemId: selectedItem.id,
          measureUnit: selectedItem.measureUnit,
          category: selectedItem.category,
          subcategory: selectedItem.subcategory,
        };
      } else {
        updatedRow = {
          ...updatedRow,
          itemId: '',
          measureUnit: '',
          category: '',
          subcategory: '',
        };
      }
    }
    // If user chose item by ID:
    else if (name === 'itemId') {
      const selectedItem = items.find((item) => item.id === value);
      if (selectedItem) {
        updatedRow = {
          ...updatedRow,
          description: selectedItem.name,
          measureUnit: selectedItem.measureUnit,
          category: selectedItem.category,
          subcategory: selectedItem.subcategory,
        };
      } else {
        updatedRow = {
          ...updatedRow,
          description: '',
          measureUnit: '',
          category: '',
          subcategory: '',
        };
      }
    }

    // If user chose a category, reset subcategory & measureUnit
    if (name === 'category') {
      updatedRow = {
        ...updatedRow,
        subcategory: '',
        measureUnit: '',
      };
    }

    updatedRows[index] = updatedRow;
    setExpenseData({ ...expenseData, rows: updatedRows });
  };

  // If editing existing expense, fetch it
  const fetchExpenseData = async () => {
    if (expenseId !== 'new') {
      setLoading(true);
      try {
        const data = await fetchExpenseById(
          apiKey,
          organizationId,
          jwtToken,
          expenseId,
          userId
        );
        if (data.expenseDate) {
          data.expenseDate = new Date(data.expenseDate);
        }
        setExpenseData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchItems = async () => {
    try {
      const fetchedItems = await getAllItems(apiKey, organizationId, jwtToken);
      setItems(fetchedItems);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchMeasureUnitsData = async () => {
    try {
      const fetchedMeasureUnits = await fetchMeasureUnits(
        apiKey,
        organizationId,
        jwtToken
      );
      setMeasureUnits(fetchedMeasureUnits);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const fetchedCategories = await fetchCategories(
        apiKey,
        organizationId,
        jwtToken
      );
      setCategories(fetchedCategories);
      console.log('Fetched categories:', fetchedCategories);
    } catch (err) {
      setError(err.message);
    }
  };

  // Load everything in parallel
  useEffect(() => {
    (async () => {
      try {
        // If we are editing, fetch expense data first
        if (isEditMode) {
          await fetchExpenseData();
        }
        await Promise.all([
          fetchCategoriesData(),
          fetchMeasureUnitsData(),
          fetchItems(),
        ]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [apiKey]);

  const handleHeaderInputChange = (event) => {
    const { name, value } = event.target;
    setExpenseData({ ...expenseData, [name]: value });
  };

  const removeRow = (index) => {
    const updatedRows = [...expenseData.rows];
    updatedRows.splice(index, 1);
    setExpenseData({ ...expenseData, rows: updatedRows });
  };

  const addRow = () => {
    const newRow = {
      itemId: '',
      description: '',
      rowNumber: '',
      quantity: '',
      measureUnit: '',
      netPrice: '',
      taxRate: '',
      bruttoPrice: '',
      discountPercentage: '',
      discountAmount: '',
      rowTotal: '',
      notes: '',
      category: '',
      subcategory: '',
    };
    setExpenseData({ ...expenseData, rows: [...expenseData.rows, newRow] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let response;
      if (expenseId === 'new') {
        response = await saveExpense(
          expenseData,
          apiKey,
          organizationId,
          jwtToken,
          userId
        );
      } else {
        response = await updateExpense(
          expenseData,
          apiKey,
          organizationId,
          jwtToken,
          userId
        );
      }
      openSnackbar(response.message, 'success');
      navigate('/expenses');
    } catch (err) {
      setError(err.message);
      openSnackbar('Failed to save expense', 'error');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Called after the user presses "Save" in the SuggestionModal.
   */
  const handleSaveSuggestions = async (updatedSuggestions) => {
    // 1) Re-fetch data so newly created categories/items/measureUnits become visible
    await fetchCategoriesData();
    await fetchItems();
    await fetchMeasureUnitsData();

    // 2) Merge new IDs into expenseData.rows
    const updatedRows = [...expenseData.rows];

    // Link row.category to newly created categoryId
    updatedSuggestions.categories.forEach((cat) => {
      if (Array.isArray(cat.suggestionforRows)) {
        cat.suggestionforRows.forEach((rowNumStr) => {
          const rowNum = parseInt(rowNumStr, 10);
          const foundRow = updatedRows.find(
            (r) => parseInt(r.rowNumber, 10) === rowNum
          );
          if (foundRow) {
            foundRow.category = cat.categoryId;
          }
        });
      }
      // Then subcategories
      (cat.subcategories || []).forEach((subcat) => {
        if (Array.isArray(subcat.suggestionforRows)) {
          subcat.suggestionforRows.forEach((rowNumStr) => {
            const rowNum = parseInt(rowNumStr, 10);
            const foundRow = updatedRows.find(
              (r) => parseInt(r.rowNumber, 10) === rowNum
            );
            if (foundRow) {
              foundRow.subcategory = subcat.subcategoryId;
            }
          });
        }
      });
    });

    // Link new itemId
    updatedSuggestions.rows
      ?.filter((row) => !row.itemId.startsWith('existing_'))
      .forEach((suggestedRow) => {
        const foundRow = updatedRows.find(
          (r) => parseInt(r.rowNumber, 10) === parseInt(suggestedRow.rowNumber, 10)
        );
        if (foundRow) {
          foundRow.itemId = suggestedRow.itemId;
        }
      });

    setExpenseData({ ...expenseData, rows: updatedRows });
    setIsSuggestionModalOpen(false);
  };

  /**
   * Once we have loaded categories/items/measureUnits (loading = false), 
   * if we came from a scanned doc, map it into expenseData. 
   * Also check if we need to open the suggestion modal.
   */
  useEffect(() => {
    if (
      !loading &&
      state?.fromDocument &&
      categories.length > 0 &&
      items.length > 0 &&
      measureUnits.length > 0
    ) {
      // Grab the doc
      let fromDocument;
      if (Array.isArray(state.fromDocument) && state.fromDocument.length > 0) {
        fromDocument = state.fromDocument[0];
      } else {
        fromDocument = state.fromDocument;
      }

      // Map it into expenseData
      const mappedExpenseData = mapFromDocumentToExpenseData(expenseData, fromDocument);
      setExpenseData(mappedExpenseData);
      console.log('Mapped expenseData AFTER categories/items loaded:', mappedExpenseData);

      // Then see if we need the suggestion modal
      const hasPotentialNewCategories =
        fromDocument.suggestions?.categories?.some((cat) => !cat.categoryId);
      const hasPotentialNewItems =
        fromDocument.suggestions?.rows?.some(
          (row) => row.stockItem && !row.itemId
        );

      if (hasPotentialNewCategories || hasPotentialNewItems) {
        setSuggestions(fromDocument.suggestions);
        setIsSuggestionModalOpen(true);
      } else {
        setSuggestions({ rows: [], suggestions: { categories: [], measureUnits: [] } });
        setIsSuggestionModalOpen(false);
      }
    }
    // eslint-disable-next-line
  }, [loading, state, categories, items, measureUnits]);

  return {
    expenseData,
    handleRowChange,
    handleHeaderInputChange,
    handleDatePickerChange,
    items,
    handleSubmit,
    handleCheckboxChange,
    setExpenseData,
    removeRow,
    addRow,
    measureUnits,
    categories,
    isEditMode,
    loading,
    error,
    isCancelled,
    handleCancel,
    suggestions,
    setSuggestions,
    isSuggestionModalOpen,
    setIsSuggestionModalOpen,
    handleSaveSuggestions,
  };
};
