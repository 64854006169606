import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllOwners } from '../../../services/ownerService';
import { getAllHorses } from '../../../services/horseService';
import { fetchUserBookings } from '../../../services/guidingMachineService';
import {
    fetchDashboardStatistics,
    fetchMonthlyCategorizedTransactions
} from '../../../services/AdminDashboardService';
import { getHorseActivityVotesAdminStatistics } from '../../../services/horseActivityVoteService';
import { categories } from '../../../pages/inventoryManagement/itemManagement/ItemUtils';

export const useDashboardData = (
    apiKey,
    organizationId,
    jwtToken,
    userId,
    month,
    year,
    isEquistab
) => {
    const { t } = useTranslation();

    // High-level counts & financial totals
    const [horseCount, setHorseCount] = useState(0);
    const [ownerCount, setOwnerCount] = useState(0);
    const [coRiderCount, setCoRiderCount] = useState(0);
    const [todayBookingCount, setTodayBookingCount] = useState(0);
    const [monthlyExpenses, setMonthlyExpenses] = useState(0);
    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [financialStatus, setFinancialStatus] = useState(0);
    const [taxStatus, setTaxStatus] = useState(0);

    // Data for bar charts (detailed categories/subcategories)
    const [currentMonthStackedExpenses, setCurrentMonthStackedExpenses] = useState([]);
    const [activeSubcategories, setActiveSubcategories] = useState(new Set());
    const [currentMonthIncomeByCategory, setCurrentMonthIncomeByCategory] = useState([]);

    // Data for line charts
    const [last12MonthsData, setLast12MonthsData] = useState([]);
    const [last30DaysItemUsageData, setLast30DaysItemUsageData] = useState([]);

    // Translated keys for the last12Months line chart
    const [translatedExpenseKey, setTranslatedExpenseKey] = useState('');
    const [translatedIncomeKey, setTranslatedIncomeKey] = useState('');

    // Inventory warnings & services data
    const [warningItems, setWarningItems] = useState({ data: [], columns: [] });
    const [services, setServices] = useState({ data: [], columns: [] });

    // Busiest times data
    const [busiestTimes, setBusiestTimes] = useState({});
    const [busiestTimesDate, setBusiestTimesDate] = useState(new Date());

    const baseOrange = '#FFA500'; // Base orange color in hex

    // Table columns
    const inventoryColumns = [
        { id: 'name', label: t('Home.AdminDashboard.itemName', { defaultValue: 'Item Name' }) },
        { id: 'quantity', label: t('Home.AdminDashboard.quantity', { defaultValue: 'Quantity' }) },
        { id: 'warningLevel', label: t('Home.AdminDashboard.warningLevel', { defaultValue: 'Warning Level' }) }
    ];

    const serviceGroupColumns = [
        { id: 'boxType', label: t('Home.AdminDashboard.boxType', { defaultValue: 'Box Type' }) },
        { id: 'serviceType', label: t('Home.AdminDashboard.serviceType', { defaultValue: 'Service Type' }) },
        { id: 'horses', label: t('Home.AdminDashboard.horses', { defaultValue: 'Horses' }) }
    ];

    // 1) Fetch main dashboard stats & set summary info
    useEffect(() => {
        const fetchData = async () => {
            try {
                // If Equistab, load horse/owner counts and today's bookings
                if (isEquistab) {
                    const horses = await getAllHorses(apiKey, organizationId, jwtToken);
                    setHorseCount(horses.length);

                    const owners = await getAllOwners(apiKey, organizationId, jwtToken);
                    setOwnerCount(owners.length);
                    setCoRiderCount(0); // adjust if needed

                    const today = new Date().toISOString().split('T')[0];
                    const bookings = await fetchUserBookings(userId, apiKey, organizationId, jwtToken);
                    setTodayBookingCount(
                        bookings.filter(booking => booking.start_time.startsWith(today)).length
                    );
                }

                // Fetch the main dashboard stats (includes last12Months summary and 'currentMonth' block)
                const stats = await fetchDashboardStatistics(apiKey, organizationId, jwtToken, userId);

                // 1A) Set numeric monthly expenses/income for the SELECTED month
                //     from the summary array (stats.Statistics).
                const currentMonthData =
                    stats.Statistics.find(
                        (m) => m.monthNumber === month && m.year === year
                    ) || { expenses: 0, income: 0 };

                setMonthlyExpenses(currentMonthData.expenses);
                setMonthlyIncome(currentMonthData.income);
                setFinancialStatus(currentMonthData.income - currentMonthData.expenses);

                // 1B) We can still use the "currentMonth" block from the server
                //     for taxes, inventory usage, warnings, etc. if that is your back-end design
                setTaxStatus(stats?.currentMonth?.taxes?.estimatedTax || 0);
                setLast30DaysItemUsageData(stats?.currentMonth?.inventory || []);

                // 1C) Prepare warnings & services table data from 'stats'
                const inventoryWarnings = prepareInventoryWarnings(stats.stockWarnings);
                const serviceGroups = prepareServiceGroups(stats.serviceGroups);
                if (isEquistab) {
                    setWarningItems({ data: inventoryWarnings, columns: inventoryColumns });
                    setServices({ data: serviceGroups, columns: serviceGroupColumns });
                }

                // 1D) Prepare last12Months line chart
                setTranslatedExpenseKey(t('Home.AdminDashboard.expense', { defaultValue: 'Expenses' }));
                setTranslatedIncomeKey(t('Home.AdminDashboard.income', { defaultValue: 'Income' }));

                // Create a copy of stats.Statistics with translated keys for Recharts
                const translatedLast12MonthsData = stats.Statistics.map((mData) => ({
                    ...mData,
                    [t('Home.AdminDashboard.expense')]: mData.expenses,
                    [t('Home.AdminDashboard.income')]: mData.income
                }));
                setLast12MonthsData(translatedLast12MonthsData);

                // 1E) Fetch busiest times for the default busiestTimesDate
                const date = busiestTimesDate.toISOString().split('T')[0];
                const busiestTimesData = await getHorseActivityVotesAdminStatistics(
                    apiKey,
                    organizationId,
                    jwtToken,
                    date
                );
                setBusiestTimes(busiestTimesData);

            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchData();
    }, [
        apiKey,
        organizationId,
        jwtToken,
        userId,
        isEquistab,
        month,
        year,
        t,
        busiestTimesDate
    ]);

    // 2) Fetch the *detailed* monthly category/subcategory transactions for the SELECTED month
    //    and store them for the bar charts
    useEffect(() => {
        const fetchMonthData = async () => {
            try {
                // This call should return an object with { expenses: [...], income: {...} }
                // for the user-selected month and year
                const currentMonthData = await fetchMonthlyCategorizedTransactions(
                    apiKey,
                    organizationId,
                    jwtToken,
                    userId,
                    month,
                    year
                );

                // Generate stacked expenses data for Recharts
                const newActiveSubcategories = new Set();
                const totalCategoriesAndSubcategories = currentMonthData.expenses.reduce(
                    (acc, category) => acc + 1 + category.subcategories.length,
                    0
                );
                const orangeShades = generateOrangeShades(baseOrange, totalCategoriesAndSubcategories);
                let shadeIndex = 0;

                const expensesData = currentMonthData.expenses.map((category) => {
                    // Each "category" in the array might have subcategories
                    const categoryData = {
                        category: category.name,
                        color: orangeShades[shadeIndex++] || baseOrange
                    };

                    // Add each subcategory as a field on the category object
                    category.subcategories.forEach((subcategory) => {
                        categoryData[subcategory.name] = subcategory.total;
                        newActiveSubcategories.add({
                            label: subcategory.name,
                            color: orangeShades[shadeIndex++] || baseOrange
                        });
                    });

                    return categoryData;
                });

                setActiveSubcategories(newActiveSubcategories);
                setCurrentMonthStackedExpenses(expensesData);

                // Generate income data for Recharts
                const currentMonthInvoiceData = Object.entries(currentMonthData.income).map(
                    ([category, amount]) => ({
                        category: getItemTypeTranslation(category),
                        amount
                    })
                );
                setCurrentMonthIncomeByCategory(currentMonthInvoiceData);

            } catch (error) {
                console.error('Error fetching month data:', error);
            }
        };

        // Only fetch if you have a valid month/year
        if (month !== undefined && year !== undefined) {
            fetchMonthData();
        }
    }, [apiKey, organizationId, jwtToken, userId, month, year, t]);

    // Utility to translate category keys
    const getTranslatedLabelForCategory = (categoryKey) => {
        const category = categories[categoryKey];
        if (!category) return categoryKey;
        return t(category.label);
    };

    // Utility to translate subcategory keys
    const getTranslatedLabelForSubcategory = (categoryKey, subcategoryKey) => {
        const category = categories[categoryKey];
        if (!category) return subcategoryKey;
        const subcategory = category.subcategories[subcategoryKey];
        if (!subcategory) return subcategoryKey;
        return t(subcategory.label);
    };

    const getItemTypeTranslation = (type) => {
        // e.g. 'Box' => t('invoiceDetailPage.itemTypes.box')
        return t(`invoiceDetailPage.itemTypes.${type.toLowerCase()}`, type);
    };

    // Prepare inventory warnings
    const prepareInventoryWarnings = (inventoryData) => {
        return inventoryData?.map((item) => ({
            id: item.id,
            name: item.name,
            warningLevel: item.warningQuantity,
            quantity: `${item.quantity} units`
        })) || [];
    };

    // Prepare service groups
    const prepareServiceGroups = (serviceGroups) => {
        return serviceGroups?.map((group) => ({
            boxType: group.boxTypeName,
            serviceType: group.services.map(s => s.serviceTypeName).join(', '),
            horses: group.services
                .filter(s => s.horses.length > 0)
                .flatMap(s => s.horses.map(horse => horse.name))
                .join(', ')
        })) || [];
    };

    // Convert hex to HSL
    function hexToHSL(H) {
        let r = 0, g = 0, b = 0;
        if (H.length === 4) {
            r = parseInt(H[1] + H[1], 16);
            g = parseInt(H[2] + H[2], 16);
            b = parseInt(H[3] + H[3], 16);
        } else if (H.length === 7) {
            r = parseInt(H[1] + H[2], 16);
            g = parseInt(H[3] + H[4], 16);
            b = parseInt(H[5] + H[6], 16);
        }
        r /= 255;
        g /= 255;
        b /= 255;
        const cmin = Math.min(r, g, b),
            cmax = Math.max(r, g, b),
            delta = cmax - cmin;
        let h = 0,
            s = 0,
            l = 0;

        if (delta === 0) h = 0;
        else if (cmax === r) h = ((g - b) / delta) % 6;
        else if (cmax === g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;
        h = Math.round(h * 60);
        if (h < 0) h += 360;
        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);
        return `hsl(${h},${s}%,${l}%)`;
    }

    // Generate multiple orange shades
    function generateOrangeShades(baseOrange, count) {
        const shades = [];
        const baseHSL = hexToHSL(baseOrange);
        const match = baseHSL.match(/^hsl\((\d+),(\d+)%,(\d+)%\)$/);
        if (match) {
            const baseHue = match[1];
            const baseSat = match[2];
            const baseLightness = parseInt(match[3], 10);
            for (let i = 0; i < count; i++) {
                const lightness = baseLightness - (i * 5) % 50;
                shades.push(`hsl(${baseHue}, ${baseSat}%, ${lightness}%)`);
            }
        }
        return shades;
    }

    // Busiest Times navigation
    async function busiestTimeNextDay() {
        const nextDay = new Date(busiestTimesDate);
        nextDay.setDate(nextDay.getDate() + 1);
        setBusiestTimesDate(nextDay);
        const fetchedData = await getHorseActivityVotesAdminStatistics(
            apiKey,
            organizationId,
            jwtToken,
            nextDay.toISOString().split('T')[0]
        );
        setBusiestTimes(fetchedData);
    }

    async function busiestTimePrevDay() {
        const prevDay = new Date(busiestTimesDate);
        prevDay.setDate(prevDay.getDate() - 1);
        setBusiestTimesDate(prevDay);
        const fetchedData = await getHorseActivityVotesAdminStatistics(
            apiKey,
            organizationId,
            jwtToken,
            prevDay.toISOString().split('T')[0]
        );
        setBusiestTimes(fetchedData);
    }

    return {
        // Basic counts
        horseCount,
        ownerCount,
        coRiderCount,
        todayBookingCount,

        // Summaries
        monthlyExpenses,
        monthlyIncome,
        financialStatus,
        taxStatus,

        // Detailed bar chart data
        currentMonthStackedExpenses,
        currentMonthIncomeByCategory,
        activeSubcategories,

        // Last12Months, items usage
        last12MonthsData,
        translatedExpenseKey,
        translatedIncomeKey,
        last30DaysItemUsageData,

        // Inventory & services
        warningItems,
        services,

        // Busiest times
        busiestTimes,
        busiestTimesDate,
        busiestTimePrevDay,
        busiestTimeNextDay
    };
};
