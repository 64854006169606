import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Button, FormControl, InputLabel, Select, MenuItem,
    Grid, IconButton, TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../hooks/useDateLocale';
import { getHorsesByUserId } from '../services/horseService';
import { fetchLocationSettings } from '../services/LocationSettingsService';
import { createHorseActivityVote, updateHorseActivityVote } from '../services/horseActivityVoteService';
import { useAuth } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import SnackbarAlert from './SnackbarAlert'; // Adjust the path as necessary

const HorseActivityVoteModal = ({ open, onClose, activity }) => {
    const { t } = useTranslation();
    const [activityData, setActivityData] = useState({
        horseId: '',
        activityType: '',
        selectedDate: new Date(),
        startTime: new Date(new Date().setMinutes(0, 0, 0)),
        endTime: new Date(new Date().setMinutes(0, 0, 0) + 2 * 60 * 60 * 1000),
        selectedSpace: ''
    });

    const [horses, setHorses] = useState([]);
    const [loading, setLoading] = useState(true);
    const { apiKey, userId, userType, organizationId, token, ownerId } = useAuth();
    const [locations, setLocations] = useState([]);
    const [showHorseFields, setShowHorseFields] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // Can be 'error', 'warning', 'info', or 'success'
    });

    useEffect(() => {
        const fetchHorses = async () => {
            try {
                const horses = await getHorsesByUserId(userId, apiKey, organizationId, token);
                setHorses(horses);
            } catch (error) {
                console.error('Error fetching horses', error);
            }
        };

        const fetchLocations = async () => {
            try {
                const locations = await fetchLocationSettings(apiKey, organizationId, token);
                const ridingArenaLocations = locations.filter(location =>
                    location.buildings.some(building => building.type.ridingArena)
                );
                setLocations(ridingArenaLocations);
            } catch (error) {
                console.error('Error fetching locations', error);
            }
        };

        fetchLocations();
        fetchHorses();
        setLoading(false);
    }, [apiKey, userId, organizationId, token, ownerId]);

    useEffect(() => {
        console.log('activity', activity);
        if (activity) {
            setActivityData({
                horseId: activity.horseId,
                activityType: activity.activityType,
                selectedDate: new Date(activity.startTime),
                startTime: new Date(activity.startTime),
                endTime: new Date(activity.endTime),
                selectedSpace: activity.selectedSpace,
                id: activity.id
            });
            if (activity.horseId) {
                setShowHorseFields(true);
            } else {
                setShowHorseFields(false);
            }
        }
    }, [activity]);

    const activityTypes = [
        'lunge',
        'riding',
        'jumping',
        'dressage',
        'rideOut',
        'walking'
    ];

    useEffect(() => {
        if (!open) {
            // Reset form when modal is closed
            setActivityData({
                horseId: '',
                activityType: '',
                selectedDate: new Date(),
                startTime: new Date(new Date().setMinutes(0, 0, 0)),
                endTime: new Date(new Date().setMinutes(0, 0, 0) + 2 * 60 * 60 * 1000),
                selectedSpace: ''
            });
            setShowHorseFields(false);
        }
    }, [open]);

    const handleStartTimeChange = (newStartTime) => {
        const fullHourTime = new Date(newStartTime.setMinutes(0, 0, 0));
        setActivityData(prevState => ({
            ...prevState,
            startTime: fullHourTime,
            endTime: new Date(fullHourTime.getTime() + 2 * 60 * 60 * 1000)
        }));
    };

    const handleChange = (field, value) => {
        setActivityData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleAddHorse = () => {
        setShowHorseFields(true);
        if (horses.length > 0) {
            setActivityData(prevState => ({
                ...prevState,
                horseId: horses.find(horse => horse.ownerId === ownerId)?.id || horses[0].id
            }));
        }
    };

    const handleRemoveHorse = () => {
        setShowHorseFields(false);
        setActivityData(prevState => ({
            ...prevState,
            horseId: '',
            activityType: ''
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        if (!activityData.selectedSpace) {
            return;
        }
        try {
            if (activity) {
                await updateHorseActivityVote(activityData, apiKey, organizationId, token);
            } else {
                await createHorseActivityVote(activityData, apiKey, organizationId, token);
            }
            onClose();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setSnackbar({
                    open: true,
                    message: t('HorseActivityModal.errorCodes.' + error.response.data.errorcode),
                    severity: 'error'
                });
            } else {
                setSnackbar({
                    open: true,
                    message: 'An unexpected error occurred. Please try again later.',
                    severity: 'error'
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar(prevState => ({
            ...prevState,
            open: false
        }));
    };

    const setToday = () => {
        setActivityData(prevState => ({
            ...prevState,
            selectedDate: new Date()
        }));
    };

    const setTomorrow = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setActivityData(prevState => ({
            ...prevState,
            selectedDate: tomorrow
        }));
    };

    const isToday = (date) => {
        const today = new Date();
        return date.toDateString() === today.toDateString();
    };

    const isTomorrow = (date) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return date.toDateString() === tomorrow.toDateString();
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {t('HorseActivityModal.title')}
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        {showHorseFields ? (
                            <>
                                <Grid item xs={10}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('HorseActivityModal.Horse')}</InputLabel>
                                        <Select
                                            value={activityData.horseId}
                                            onChange={(e) => handleChange('horseId', e.target.value)}
                                        >
                                            {horses.map((horse) => (
                                                <MenuItem key={horse.id} value={horse.id}>
                                                    {horse.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={handleRemoveHorse}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('HorseActivityModal.activityType')}</InputLabel>
                                        <Select
                                            value={activityData.activityType}
                                            onChange={(e) => handleChange('activityType', e.target.value)}
                                        >
                                            {activityTypes.map((activity) => (
                                                <MenuItem key={activity} value={activity}>
                                                    {t(`HorseActivityModal.${activity}`)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddHorse}
                                >
                                    {t('HorseActivityModal.addHorse')}
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel>{t('HorseActivityModal.location')}</InputLabel>
                                <Select
                                    value={activityData.selectedSpace}
                                    onChange={(e) => handleChange('selectedSpace', e.target.value)}
                                    required
                                >
                                    {locations.flatMap((location) =>
                                        location.buildings
                                            .filter(building => building.type.ridingArena)
                                            .map(building => (
                                                <MenuItem key={building.id} value={building.id}>
                                                    {building.name}
                                                </MenuItem>
                                            ))
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker
                                label={t('HorseActivityModal.date')}
                                value={activityData.selectedDate}
                                onChange={(newDate) => handleChange('selectedDate', newDate)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={12} container spacing={1} justifyContent="flex-start">
                            <Grid item>
                                <Button
                                    variant={isToday(activityData.selectedDate) ? "contained" : "outlined"}
                                    onClick={setToday}
                                >
                                    {t('HorseActivityModal.today')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={isTomorrow(activityData.selectedDate) ? "contained" : "outlined"}
                                    onClick={setTomorrow}
                                >
                                    {t('HorseActivityModal.tomorrow')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                label={t('HorseActivityModal.startTime')}
                                value={activityData.startTime}
                                onChange={handleStartTimeChange}
                                minutesStep={60}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimePicker
                                label={t('HorseActivityModal.endTime')}
                                value={activityData.endTime}
                                onChange={(newEndTime) => handleChange('endTime', newEndTime)}
                                minutesStep={60}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={loading}>{t('common.cancel')}</Button>
                    <Button onClick={handleSubmit} color="primary" disabled={loading}>{t('common.confirm')}</Button>
                </DialogActions>
            </Dialog>
            <SnackbarAlert
                open={snackbar.open}
                onClose={handleSnackbarClose}
                message={snackbar.message}
                severity={snackbar.severity}
            />
        </>
    );
};

export default HorseActivityVoteModal;
